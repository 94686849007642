import { Link } from "react-router";
import { useTranslation } from "react-i18next";
import { Nav, NavDropdown } from "react-bootstrap";
import HourglassGlobals from "../../helpers/globals";
import { Permission } from "../../types/permission";

export function NavScheduling() {
  const { t } = useTranslation();
  const canViewRegular = HourglassGlobals.permissions.has(Permission.ViewSchedules);

  return (
    <Nav className="flex-column hg-nav">
      {canViewRegular && (
        <>
          <NavDropdown.Item as={Link} to="/scheduling/mm">
            {t("conganalysis.attendance.midweek")}
          </NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/scheduling/wm">
            {t("conganalysis.attendance.weekend")}
          </NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/scheduling/avattendant">
            {t("schedules.ava.title")}
          </NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/scheduling/cleaning">
            {t("schedules.cleaning.title")}
          </NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/scheduling/fsMeeting">
            {t("schedules.field-service.title")}
          </NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/scheduling/publicWitnessing">
            {t("schedules.public-witnessing.title")}
          </NavDropdown.Item>
        </>
      )}
      {(canViewRegular || HourglassGlobals.permissions.has(Permission.UpdateMaintenanceSchedules)) && (
        <NavDropdown.Item as={Link} to="/scheduling/maintenance">
          {t("schedules.maintenance.title")}
        </NavDropdown.Item>
      )}
      {(canViewRegular || HourglassGlobals.permissions.has(Permission.UpdateCustomAssignments)) && (
        <NavDropdown.Item as={Link} to="/scheduling/custom">
          {t("general.custom")}
        </NavDropdown.Item>
      )}
      {canViewRegular && (
        <>
          <NavDropdown.Divider />
          <NavDropdown.Item as={Link} to="/scheduling/memorial">
            {t("schedules.events.memorial")}
          </NavDropdown.Item>
          <NavDropdown.Divider />
          {(HourglassGlobals.permissions.has(Permission.ViewUsers) ||
            HourglassGlobals.permissions.has(Permission.ViewUsersMinimal)) && (
            <NavDropdown.Item as={Link} to="/scheduling/privilege_matrix">
              {t("schedules.privileges.title")}
            </NavDropdown.Item>
          )}
          <NavDropdown.Item as={Link} to="/scheduling/absence">
            {t("schedules.absence.title")}
          </NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/scheduling/events">
            {t("schedules.events.title")}
          </NavDropdown.Item>
          {HourglassGlobals.permissions.has(Permission.UpdateCongregation) && (
            <NavDropdown.Item as={Link} to="/scheduling/settings">
              {t("Jcy-FH-fbr.title")}
            </NavDropdown.Item>
          )}
        </>
      )}
    </Nav>
  );
}
