import { useState } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { Activity, CurrencyExchange, QuestionCircle } from "react-bootstrap-icons";
import Accordion from "react-bootstrap/Accordion";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router";
import { ReactSVG } from "react-svg";
import congregationIcon from "../../assets/congregation.svg";
import reportsIcon from "../../assets/graph.svg";
import logo from "../../assets/hourglass-logo-brown.svg";
import schedulingIcon from "../../assets/meeting.svg";
import publishersIcon from "../../assets/publishers.svg";
import HourglassGlobals from "../../helpers/globals";
import { Permission } from "../../types/permission";
import { NavCongregation } from "./congregation";
import { NavPublishers } from "./publishers";
import { NavReports } from "./reports";
import { NavScheduling } from "./scheduling";
import { UserStatus } from "../../types/user";

function canViewSchedulesMenu(): boolean {
  return (
    HourglassGlobals.permissions.has(Permission.ViewSchedules) ||
    HourglassGlobals.permissions.has(Permission.UpdateMaintenanceSchedules) ||
    HourglassGlobals.permissions.has(Permission.UpdateCustomAssignments)
  );
}

//this is the sidebar navigation for desktop/larger screens
function MainNav() {
  const { t } = useTranslation();

  const isCO = HourglassGlobals.authUser.status === UserStatus.CO;

  return (
    <div className="d-flex flex-column side-section">
      <Link to="/">
        <div className="brand-label overflow-hidden p-2 d-none d-md-block">
          <img src={logo} className="top-nav-logo" alt="Hourglass logo" />
        </div>
      </Link>
      <div className="d-none d-md-flex d-print-none" id="nav-wrap">
        <Accordion flush className="nav" id="mainNav">
          <Accordion.Item eventKey="publishers" id="publishers-nav">
            <Accordion.Header className="nav-link">
              <ReactSVG src={publishersIcon} />
              <span className="nav-max-width-title">{t("list.publishers.title")}</span>
            </Accordion.Header>
            <Accordion.Body>
              <NavPublishers />
            </Accordion.Body>
          </Accordion.Item>

          {HourglassGlobals.permissions.has(Permission.ViewSummary) && (
            <Accordion.Item eventKey="reports" id="reports-nav">
              <Accordion.Header className="nav-link">
                <ReactSVG src={reportsIcon} />
                {t("nav.reports.0")}
              </Accordion.Header>
              <Accordion.Body>
                <NavReports />
              </Accordion.Body>
            </Accordion.Item>
          )}

          {canViewSchedulesMenu() && (
            <Accordion.Item eventKey="scheduling" id="scheduling-nav">
              <Accordion.Header className="nav-link">
                <ReactSVG src={schedulingIcon} />
                {t("schedules.scheduling")}
              </Accordion.Header>
              <Accordion.Body>
                <NavScheduling />
              </Accordion.Body>
            </Accordion.Item>
          )}

          <Accordion.Item eventKey="congregation" id="cong-nav">
            <Accordion.Header className="nav-link">
              <ReactSVG src={congregationIcon} />
              {t("list.congregation.title")}
            </Accordion.Header>
            <Accordion.Body>
              <NavCongregation />
            </Accordion.Body>
          </Accordion.Item>

          {isCO && (
            <Link to="/circuit">
              <Accordion.Item eventKey="circuit">
                <Accordion.Header className="nav-link no-arrow">
                  <Activity className="me-2" />
                  {t("circuit.circuit")}
                </Accordion.Header>
              </Accordion.Item>
            </Link>
          )}

          <Link to="/docs">
            <Accordion.Item eventKey="docs">
              <Accordion.Header className="nav-link no-arrow">
                <QuestionCircle className="me-2" />
                {t("nav.help")}
              </Accordion.Header>
            </Accordion.Item>
          </Link>

          <a href="https://www.paypal.com/donate/?hosted_button_id=LEWREP6SGSV98" target="_blank" rel="noreferrer">
            <Accordion.Item eventKey="donate">
              <Accordion.Header className="nav-link no-arrow">
                <CurrencyExchange className="me-2" />
                {t("www.donate-button")}
              </Accordion.Header>
            </Accordion.Item>
          </a>
        </Accordion>
      </div>
    </div>
  );
}

export default MainNav;

enum MobileNavKeys {
  None,
  Publishers,
  Reports,
  Scheduling,
  Congregation,
  Help,
  Donate,
}

//mobile nav is the bottom bar, which has specific shortcut items
export function MobileNav() {
  const { t } = useTranslation();
  const loc = useLocation();
  const [active, setActive] = useState(MobileNavKeys.None);
  if (loc.pathname.startsWith("/nav/publishers") && active !== MobileNavKeys.Publishers) {
    setActive(MobileNavKeys.Publishers);
  } else if (loc.pathname.startsWith("/user/") && active !== MobileNavKeys.Publishers) {
    setActive(MobileNavKeys.Publishers);
  } else if (loc.pathname.startsWith("/docs") && active !== MobileNavKeys.Help) {
    setActive(MobileNavKeys.Help);
  } else if (loc.pathname === "/" && active !== MobileNavKeys.None) setActive(MobileNavKeys.None);

  return (
    <Navbar fixed="bottom" id="bottomNavbar" className="d-block d-md-none d-print-none">
      <Nav activeKey={active} className="justify-content-evenly">
        <Nav.Item>
          <Nav.Link
            as={Link}
            to="/nav/publishers"
            className="nav-link"
            eventKey={MobileNavKeys.Publishers}
            onClick={() => setActive(MobileNavKeys.Publishers)}
          >
            <figure className="bottom-nav">
              <ReactSVG src={publishersIcon} />
              <figcaption>{t("list.publishers.title")}</figcaption>
            </figure>
          </Nav.Link>
        </Nav.Item>
        {HourglassGlobals.permissions.has(Permission.ViewSummary) && (
          <Nav.Item>
            <Nav.Link
              as={Link}
              to="/nav/reports"
              className="nav-link"
              eventKey={MobileNavKeys.Reports}
              onClick={() => setActive(MobileNavKeys.Reports)}
            >
              <figure className="bottom-nav">
                <ReactSVG src={reportsIcon} />
                <figcaption>{t("nav.reports.0")}</figcaption>
              </figure>
            </Nav.Link>
          </Nav.Item>
        )}
        {canViewSchedulesMenu() && (
          <Nav.Item>
            <Nav.Link
              as={Link}
              to="/nav/scheduling"
              className="nav-link"
              eventKey={MobileNavKeys.Scheduling}
              onClick={() => setActive(MobileNavKeys.Scheduling)}
            >
              <figure className="bottom-nav">
                <ReactSVG src={schedulingIcon} />
                <figcaption>{t("schedules.scheduling")}</figcaption>
              </figure>
            </Nav.Link>
          </Nav.Item>
        )}
        <Nav.Item>
          <Nav.Link
            as={Link}
            to="/nav/congregation"
            className="nav-link"
            eventKey={MobileNavKeys.Congregation}
            onClick={() => setActive(MobileNavKeys.Congregation)}
          >
            <figure className="bottom-nav">
              <ReactSVG src={congregationIcon} />
              <figcaption>{t("list.congregation.title")}</figcaption>
            </figure>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            as={Link}
            to="/docs/"
            className="nav-link"
            eventKey={MobileNavKeys.Help}
            onClick={() => setActive(MobileNavKeys.Help)}
          >
            <figure className="bottom-nav">
              <QuestionCircle />
              <figcaption>{t("nav.help")}</figcaption>
            </figure>
          </Nav.Link>
        </Nav.Item>
      </Nav>
    </Navbar>
  );
}
