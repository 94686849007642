import { Button, Col, Dropdown, DropdownButton, Form, Nav } from "react-bootstrap";
import {
  createTime,
  dateToString,
  firstMondayOfMonth,
  getDayjs,
  getHourFormat,
  hour12Period,
  HourFormat,
  HourPeriod,
  ISODateFormat,
  localizedDayOfWeek,
  localizedMonthNames,
  Month,
  parseTime,
  supportsMonthInput,
  weekCount,
} from "../../helpers/dateHelpers";
import { ArrowLeftSquare, ArrowRightSquare, Calendar3 } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { storeDate, storeNumber } from "../../helpers/dateStored";
import CalendarToday from "./CalendarToday";
import dayjs from "../../helpers/dayjs";
import { Dayjs } from "dayjs";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { ISODateString } from "../../types/date";
import { isMobileSafari } from "react-device-detect";

export enum MenuType {
  Year = "year",
  Day = "date",
  Month = "month",
}

export default function DateMenu(props: {
  date: Date;
  setDate: (newDate: Date) => void;
  weeks?: number;
  setWeeks?: (set: number) => void;
  saveDate?: (date: Date) => void;
  menuType: MenuType;
  saveKey?: string;
  hidePicker?: boolean;
  minYear?: number;
  maxYear?: number;
  minDate?: ISODateString;
  maxDate?: ISODateString;
}): JSX.Element {
  const { t } = useTranslation();

  const handleDateChange = (date: ISODateString): void => {
    const day = getDateOrMonth(date);
    if (!day.isValid()) return;

    if (props.weeks !== undefined && props.setWeeks !== undefined && props.weeks !== 1) {
      props.setWeeks(weekCount(day.toDate()));
      props.setDate(firstMondayOfMonth(day.toDate()));
    } else {
      props.setDate(day.toDate());
    }
    if (props.saveKey) {
      switch (props.menuType) {
        case MenuType.Year:
          storeDate(props.saveKey, day.startOf("year").toDate());
          break;
        case MenuType.Month:
          storeDate(props.saveKey, day.startOf("month").toDate());
          break;
        default:
          storeDate(props.saveKey, day.toDate());
      }
    }
  };

  const dateWithUnitChange = (unit: number): Dayjs => {
    let day: Dayjs;
    switch (props.menuType) {
      case MenuType.Year:
        day = getDayjs(props.date).add(unit, "year");
        break;
      case MenuType.Month:
        day = getDateOrMonth(dateToString(props.date)).add(unit, "month");
        break;
      default:
        day = getDayjs(props.date).add(unit, "week");
        break;
    }
    return day;
  };

  const unitChange = (unit: number) => {
    handleDateChange(dateWithUnitChange(unit).format(ISODateFormat));
  };

  const rightArrowDisabled = (): boolean => {
    if (!!props.maxYear && props.date.getFullYear() >= props.maxYear) return true;
    if (!props.maxDate) return false;
    const maxDjs = getDayjs(props.maxDate);
    return dateWithUnitChange(1).isAfter(maxDjs);
  };

  const leftArrowDisabled = (): boolean => {
    if (!!props.minYear && props.date.getFullYear() <= props.minYear) return true;
    if (props.minDate) {
      const minDjs = getDateOrMonth(props.minDate);
      return dateWithUnitChange(-1).isBefore(minDjs);
    }
    return false;
  };

  return (
    <>
      <Button variant="secondary" className="border-0" onClick={() => unitChange(-1)} disabled={leftArrowDisabled()}>
        <ArrowLeftSquare size="1.5em" />
      </Button>
      <Button
        variant="secondary"
        className="border-0"
        onClick={() => handleDateChange(dayjs().format(ISODateFormat))}
        title={t("general.today")}
      >
        <CalendarToday size="1.5em" />
      </Button>
      <Button variant="secondary" className="border-0" onClick={() => unitChange(1)} disabled={rightArrowDisabled()}>
        <ArrowRightSquare size="1.5em" />
      </Button>
      {!props.hidePicker && (
        <HourglassDatePicker
          menuType={props.menuType}
          date={dateToString(props.date)}
          onDateChange={handleDateChange}
          required
          min={props.minDate}
          max={props.maxDate}
        />
      )}
    </>
  );
}

// parse a string into regular date or month format
function getDateOrMonth(dateStr: string): Dayjs {
  return dayjs(dateStr, [ISODateFormat, "YYYY-MM"]);
}

// since the dates in the props here could be an iso string, or just YYYY-MM, we're using the string type
export function HourglassDatePicker(props: {
  menuType: MenuType;
  date: ISODateString;
  onDateChange: (date: ISODateString) => void;
  required?: boolean;
  min?: string;
  max?: string;
  className?: string;
}) {
  const { i18n } = useTranslation();
  const [date, setDate] = useState(props.date);
  const day = date ? getDateOrMonth(date) : dayjs(null);

  const monthNames = useMemo(() => localizedMonthNames(i18n.language), [i18n.language]);

  useEffect(() => {
    setDate(props.date);
  }, [props.date]);

  const nativeSupport = () => {
    if (props.menuType === MenuType.Day) return true;
    return props.menuType === MenuType.Month && supportsMonthInput;
  };

  const nativeValue = (): string => {
    if (!day.isValid()) return date;
    switch (props.menuType) {
      case MenuType.Day:
        // you end up with weird behavior if you return something like day.format() here
        // because it wants to take 1/2-digit years and turn them into 1900
        return date;
      case MenuType.Month: {
        const month = Month.fromDate(day.toDate());
        return supportsMonthInput ? month.toString() : month.toDateString();
      }
      case MenuType.Year:
        return day.year().toString();
    }
  };

  const nativePickerType = nativeSupport() ? props.menuType : "date";

  //no browser support for just picking a year
  if (props.menuType === MenuType.Year) {
    return <b>{nativeValue()}</b>;
  }

  const className: string = props.className ? props.className : "";

  if (props.menuType === MenuType.Month && !nativeSupport()) {
    const month = Month.fromString(date);
    const minMonth = props.min ? Month.fromString(props.min) : new Month(new Date().getFullYear() - 3, 1);
    const defaultMaxMonth = Month.fromDate(new Date());
    defaultMaxMonth.addMonths(36);
    const maxMonth = props.max ? Month.fromString(props.max) : defaultMaxMonth;
    const possibleYears: number[] = [];
    for (let i = minMonth.year; i <= maxMonth.year; i++) {
      possibleYears.push(i);
    }

    const isDisabled = (year: number, month: number): boolean => {
      const testMonth = new Month(year, month);
      return testMonth.before(minMonth) || testMonth.after(maxMonth);
    };

    return (
      <span>
        <DropdownButton variant="secondary" title={monthNames[month.month - 1]}>
          {Array.from(Array(12)).map((_, i) => (
            <Dropdown.Item
              key={i}
              disabled={isDisabled(month.year, i + 1)}
              onClick={() => {
                const selected = new Month(month.year, i + 1);
                setDate(selected.toString());
                props.onDateChange(selected.toString());
              }}
            >
              {monthNames[i]}
            </Dropdown.Item>
          ))}
        </DropdownButton>
        <DropdownButton variant="secondary" title={month.year}>
          {possibleYears.map((year) => (
            <Dropdown.Item
              key={year}
              onClick={() => {
                const selected = new Month(year, month.month);
                setDate(selected.toString());
                props.onDateChange(selected.toString());
              }}
            >
              {year}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      </span>
    );
  }

  // with mobile safari, the regular classes caused nothing to be visible at all (no placeholder, no calendar icon...)
  const nativeClassName = isMobileSafari ? `m-0 ${className}` : `date-picker-input h4 bg-transparent m-0 ${className}`;

  return (
    <input
      className={nativeClassName}
      required={props.required}
      type={nativePickerType}
      min={props.min}
      max={props.max}
      value={nativeValue()}
      onChange={(e) => {
        const val = e.currentTarget.value;
        setDate(val);
        if (getDateOrMonth(val).isValid()) props.onDateChange(val);
      }}
    />
  );
}

const Hours12 = [7, 8, 9, 10, 11, 12, 1, 2, 3, 4, 5, 6];
const Hours24 = [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 0, 1, 2, 3, 4, 5, 6];
const Minutes5 = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];
const Minutes15 = [0, 15, 30, 45];

function dayPeriodOptions(locale: string): string[] {
  const am = "AM";
  const pm = "PM";
  const defaultOptions = [am, pm];
  if (!window.Intl) return defaultOptions;
  const dtf = new Intl.DateTimeFormat(locale);
  const options = [dtf.formatToParts(new Date(2022, 1, 1, 8)).find((p) => p.type === "dayPeriod")?.value || am];
  options.push(dtf.formatToParts(new Date(2022, 1, 1, 16)).find((p) => p.type === "dayPeriod")?.value || pm);

  return options;
}

// time strings passed in here, and used in the callback, are always of the form "00:00" to "23:55"
export function HourglassTimePicker(props: {
  time?: string;
  onTimeChange: (time: string) => void;
  className?: string;
  disabled?: boolean;
  invalid?: boolean;
  granularity?: 5 | 15;
  min?: string;
  defaultPM?: boolean;
  requireHour?: boolean;
}) {
  const { i18n } = useTranslation();
  const [hourFormat, setHourFormat] = useState(getHourFormat(i18n.language));
  const [dayPeriods, setDayPeriods] = useState<string[]>(dayPeriodOptions(i18n.language));
  const [time, setTime] = useState(props.time || "");

  const parsed = parseTime(props.time || "");
  const [selectedMinute, setSelectedMinute] = useState<number>(parsed.minute);

  const hp = hour12Period(parsed.hour);
  const initialSelectedHour = (): number => {
    if (hourFormat === HourFormat.H12) return hour12Period(parsed.hour).hour;
    return parsed.hour;
  };
  const [selectedHour, setSelectedHour] = useState<number>(props.time ? initialSelectedHour() : -1);
  const [selectedPeriod, setSelectedPeriod] = useState<HourPeriod>(props.defaultPM ? HourPeriod.PM : hp.period);

  useEffect(() => {
    setHourFormat(getHourFormat(i18n.language));
    setDayPeriods(dayPeriodOptions(i18n.language));
  }, [i18n.language]);

  useEffect(() => {
    if (time) props.onTimeChange(time);
    // if we put onTimeChange as a dep, this will get called all the time, because it changes with every parent render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);

  const changeTime = (kind: "hour" | "minute" | "period", e: ChangeEvent<HTMLSelectElement>) => {
    const val = e.currentTarget.value;
    let hourPeriod = selectedPeriod,
      hour = selectedHour,
      minute = selectedMinute;
    switch (kind) {
      case "hour":
        hour = parseInt(val);
        setSelectedHour(hour);
        if (hour === 12 || (hour >= 1 && hour <= 4)) hourPeriod = HourPeriod.PM;
        break;
      case "minute":
        minute = parseInt(val);
        setSelectedMinute(minute);
        break;
      case "period":
        hourPeriod = parseInt(val) === HourPeriod.PM ? HourPeriod.PM : HourPeriod.AM;
        break;
    }

    setSelectedPeriod(hourPeriod);

    if (hourFormat === HourFormat.H12) {
      if (hour === -1) return;
      if (hourPeriod === HourPeriod.PM && hour < 12) hour += 12;
      else if (hourPeriod === HourPeriod.AM && hour === 12) hour = 0;
    }
    const newTime = createTime(hour, minute);
    setTime(newTime);
  };

  const hourOptions = hourFormat === HourFormat.H12 ? Hours12 : Hours24;
  const minuteOptions = props.granularity === 15 ? Minutes15 : Minutes5;
  if (!minuteOptions.some((m) => selectedMinute === m)) {
    // the selected minute is invalid. set it to the closest valid item.
    const diffs = minuteOptions.map((m) => Math.abs(m - selectedMinute));
    const smallestDiff = Math.min(...diffs);
    const smallestIndex = diffs.findIndex((d) => d === smallestDiff);
    const newMinute = minuteOptions[smallestIndex];
    if (newMinute) setSelectedMinute(newMinute);
  }

  if (props.min) {
    const minTime = parseTime(props.min);
    const curTime = parseTime(time);

    if (minTime.valid && curTime.hour <= minTime.hour && curTime.minute <= minTime.minute && curTime.hour < 23) {
      const computeNewTime = (): { hour: number; minute: number } => {
        const maxMinutes = minuteOptions[minuteOptions.length - 1] ?? 0;
        const curMinIndex = minuteOptions.findIndex((m) => m === minTime.minute);
        const nextMinuteOption =
          curMinIndex >= 0 && curMinIndex < minuteOptions.length ? (minuteOptions[curMinIndex + 1] ?? 0) : 0;

        if (minTime.hour === 23) {
          if (minTime.minute === maxMinutes) return { hour: 23, minute: maxMinutes };
          return { hour: 23, minute: nextMinuteOption };
        }

        return { hour: minTime.hour + 1, minute: curTime.minute };
        // else return { hour: minTime.hour, minute: nextMinuteOption };
      };
      const newTime = computeNewTime();
      if (props.time && !curTime.valid) {
        // make sure the new time is after the props time; this is for initial set
        const propsTime = parseTime(props.time);
        if (propsTime.hour > newTime.hour || (propsTime.hour === newTime.hour && propsTime.minute > newTime.minute)) {
          newTime.hour = propsTime.hour;
          newTime.minute = propsTime.minute;
        }
      }

      const hp = hour12Period(newTime.hour);
      setSelectedHour(hourFormat === HourFormat.H12 ? hp.hour : newTime.hour);
      setSelectedMinute(newTime.minute);
      setSelectedPeriod(hp.period);
      setTime(createTime(newTime.hour, newTime.minute));
    }
  }

  return (
    <>
      <Col xs="auto" className="gx-1">
        <Form.Select
          disabled={props.disabled === true}
          size="sm"
          value={selectedHour}
          isInvalid={props.invalid === true}
          onChange={(e) => changeTime("hour", e)}
          required={props.requireHour === true}
        >
          <option value="" disabled={!!time} />
          {hourOptions.map((h) => (
            <option key={h} value={h}>
              {h}
            </option>
          ))}
        </Form.Select>
      </Col>
      <Col xs="auto" className="gx-1">
        <Form.Select
          disabled={props.disabled === true}
          size="sm"
          value={selectedMinute}
          onChange={(e) => changeTime("minute", e)}
        >
          {minuteOptions.map((m) => (
            <option key={m} value={m}>
              {m.toString().padStart(2, "0")}
            </option>
          ))}
        </Form.Select>
      </Col>
      {hourFormat === HourFormat.H12 && (
        <Col xs="auto" className="gx-1">
          <Form.Select
            disabled={props.disabled === true}
            size="sm"
            value={selectedPeriod}
            isInvalid={props.invalid === true}
            onChange={(e) => changeTime("period", e)}
          >
            <option value={HourPeriod.AM}>{dayPeriods[0]}</option>
            <option value={HourPeriod.PM}>{dayPeriods[1]}</option>
          </Form.Select>
        </Col>
      )}
    </>
  );
}

export function HourglassDayOfWeekPicker(props: { date: ISODateString; onChange: (date: ISODateString) => void }) {
  // no idea why it's complaining here; this is a perfectly fine use of hooks
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t, i18n } = useTranslation();
  const dj = getDayjs(props.date);
  const dow = dj.isoWeekday();
  const dowIndex = dow - 1;
  // create a dropdown with each localized day
  const days: string[] = [];
  for (let i = 1; i <= 7; i++) {
    days.push(localizedDayOfWeek(i, i18n.language));
  }

  const selectDay = (i: number) => {
    const djs = dj.isValid() ? dj : dayjs();
    const newDate = djs.isoWeekday(i + 1);
    props.onChange(newDate.format(ISODateFormat));
  };

  const title = () => {
    if (!dj.isValid() || !props.date) return <i>{t("general.none-selected")}</i>;
    return days[dowIndex];
  };

  return (
    <DropdownButton variant="secondary" title={title()}>
      {days.map((d, i) => (
        <Dropdown.Item key={i} disabled={!!props.date && i === dowIndex} onClick={() => selectDay(i)}>
          {d}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  );
}

export function MonthScheduleMenu(props: {
  date: Date;
  setDate: (date: Date) => void;
  dateSaveKey: string;
  months?: number;
  setMonths?: (set: number) => void;
}) {
  const handleChange = (val: number) => {
    if (props.setMonths) {
      storeNumber("wm_months", val);
      props.setMonths(val);
    }
  };

  return (
    <Nav className="justify-content-center">
      <DateMenu date={props.date} setDate={props.setDate} menuType={MenuType.Month} saveKey={props.dateSaveKey} />
      {!!props.months && !!props.setMonths && (
        <DropdownButton variant="link" className="ms-1" title={<Calendar3 size="1.2em" />}>
          <Dropdown.Item onClick={() => handleChange(1)}>1</Dropdown.Item>
          <Dropdown.Item onClick={() => handleChange(2)}>2</Dropdown.Item>
          <Dropdown.Item onClick={() => handleChange(3)}>3</Dropdown.Item>
        </DropdownButton>
      )}
    </Nav>
  );
}
