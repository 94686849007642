import { FormEvent, useState } from "react";
import { Card, Col, Container, DropdownButton, Navbar, Row, Table } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import logo from "../../assets/hourglass-logo-brown.svg";

import { Step } from "../../types/register/registerState";
import { circuitApi } from "../../api/circuit";
import { ShowStatus, Status } from "../scheduling/common";
import { LanguagePicker } from "../languagePicker";
import { localizedCountryNames, localizedLanguageNames } from "../../helpers/dateHelpers";
import ReCAPTCHA from "react-google-recaptcha";
import { congregationApi } from "../../api/cong";
import { encodeUnicodeToBase64 } from "../../helpers/base64";
import { Youtube } from "react-bootstrap-icons";

export function CoRegistration() {
  const { t } = useTranslation();
  return (
    <Container fluid="md">
      <Navbar>
        <Navbar.Brand>
          <img src={logo} className="App-logo" alt="logo" />
          {t("register.heading")}
        </Navbar.Brand>
      </Navbar>
      <RegistrationStep />
    </Container>
  );
}

function RegistrationStep() {
  const { t, i18n } = useTranslation();
  const [validated, setValidated] = useState(false);
  const [regStep, setRegStep] = useState(Step.Init);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [jwpub, setJwpub] = useState("");
  const [country, setCountry] = useState(1);
  const [language, setLanguage] = useState(1);
  const [circuitName, setCircuitName] = useState("");
  const [status, setStatus] = useState(Status.Null);
  const [recaptchaValidated, setRecaptchaValidated] = useState(false);

  const recaptchaChange = async (value: string | null) => {
    if (!value) return;

    try {
      await congregationApi.registerRecaptcha(value, window.HGGlobal.state);
      setRecaptchaValidated(true);
    } catch {
      setRecaptchaValidated(false);
    }
  };

  const handleStepOne = (e: FormEvent<HTMLFormElement>) => {
    setValidated(true);

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    setRegStep(Step.Info);
  };

  const selectedLanguage = (_code: string, localeId: number) => {
    setLanguage(localeId);
  };

  const handleSubmit = async () => {
    const encoded = encodeUnicodeToBase64(
      JSON.stringify({
        name: name,
        email: email,
        jwpub: jwpub,
        circuitName: circuitName,
        country: `${country}`,
        language: `${language}`,
      }),
    );
    const payload = `Name: ${name}
Email: ${email}
JWPub: ${jwpub}
Circuit: ${circuitName}
Country: ${country} (${localizedCountryNames(i18n.language).find((c) => c.id === country)?.localized})
Language: ${language} (${localizedLanguageNames(i18n.language).find((l) => l.id === language)?.localized})
Code: ${encoded}`;

    try {
      setStatus(Status.Pending);
      await circuitApi.coReg(payload);
      setStatus(Status.Success);
      setTimeout(() => {
        setRegStep(Step.Finish);
      }, 1000);
    } catch {
      setStatus(Status.Failure);
    }
  };

  switch (regStep) {
    case Step.Init:
      return (
        <Form noValidate validated={validated} onSubmit={handleStepOne}>
          <Card style={{ maxWidth: "40rem" }}>
            <Card.Header>
              {t("circuit.co-signup")}
              <DropdownButton
                className="float-end"
                size="sm"
                variant="secondary"
                title={
                  localizedLanguageNames(i18n.language).find((l) => l.id === language)?.localized ??
                  t("congprofile.language")
                }
              >
                <LanguagePicker onChange={selectedLanguage} />
              </DropdownButton>
            </Card.Header>
            <Card.Body>
              <Form.Group as={Row} className="mb-3" controlId="name">
                <Form.Label column sm="5">
                  {t("general.name")}
                </Form.Label>
                <Col sm="7">
                  <Form.Control required autoFocus value={name} onChange={(e) => setName(e.target.value)} />
                  <Form.Control.Feedback type="invalid">{t("general.required")}</Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3" controlId="personalEmail">
                <Form.Label column sm="5">
                  <div>{t("circuit.personal-email")}</div>
                </Form.Label>
                <Col sm="7">
                  <Form.Control
                    required
                    type="email"
                    placeholder="email@example.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">{t("general.required")}</Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3" controlId="jwpubEmail">
                <Form.Label column sm="5">
                  {t("circuit.jwpub-email")}
                </Form.Label>
                <Col sm="7">
                  <Form.Control
                    required
                    type="email"
                    placeholder="email@jwpub.org"
                    value={jwpub}
                    onChange={(e) => setJwpub(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">{t("general.required")}</Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3" controlId="circuitName">
                <Form.Label column sm="5">
                  {t("circuit.circuit-name")}
                </Form.Label>
                <Col sm="7">
                  <Form.Control
                    required
                    placeholder="NY-99"
                    value={circuitName}
                    onChange={(e) => setCircuitName(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">{t("general.required")}</Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3" controlId="country">
                <Form.Label column sm="5">
                  {t("congprofile.country")}
                </Form.Label>
                <Col sm="7">
                  <Form.Select required value={country} onChange={(e) => setCountry(Number(e.target.value))}>
                    {localizedCountryNames(i18n.language).map((ctry) => (
                      <option key={ctry.id} value={ctry.id}>
                        {ctry.localized}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              </Form.Group>

              <Form.Group className="mb-3 float-end">
                <Form.Check
                  required
                  id="iamaco"
                  label={t("circuit.i-am-a-co")}
                  feedback={t("circuit.you-must-agree")}
                  feedbackType="invalid"
                />
              </Form.Group>
            </Card.Body>
            <Card.Footer>
              <Row>
                <Col>
                  <Button href="https://youtu.be/IAJ6MDL9D9s" variant="danger" target="_blank" className="p-2">
                    <Youtube size="2em" />
                  </Button>
                </Col>
                <Col>
                  <Button className="float-end" type="submit">
                    {t("tour.button.next")}
                  </Button>
                </Col>
              </Row>
            </Card.Footer>
          </Card>
        </Form>
      );
    case Step.Info:
      return (
        <Card style={{ maxWidth: "40rem" }}>
          <Card.Header>Verify</Card.Header>
          <Table borderless style={{ border: 0 }}>
            <tbody>
              <tr>
                <td className="fw-bolder">{t("general.name")}</td>
                <td className="fw-lighter">{name}</td>
              </tr>
              <tr>
                <td className="fw-bolder">{t("circuit.personal-email")}</td>
                <td className="fw-lighter">{email}</td>
              </tr>
              <tr>
                <td className="fw-bolder">{t("circuit.jwpub-email")}</td>
                <td className="fw-lighter">{jwpub}</td>
              </tr>
              <tr>
                <td className="fw-bolder">{t("circuit.circuit-name")}</td>
                <td className="fw-lighter">{circuitName}</td>
              </tr>
              <tr>
                <td className="fw-bolder">{t("congprofile.country")}</td>
                <td className="fw-lighter">
                  {localizedCountryNames(i18n.language).find((c) => c.id === country)?.localized}
                </td>
              </tr>
              <tr>
                <td className="fw-bolder">{t("congprofile.language")}</td>
                <td className="fw-lighter">
                  {localizedLanguageNames(i18n.language).find((l) => l.id === language)?.localized}
                </td>
              </tr>
            </tbody>
          </Table>
          <Card.Footer>
            <Row className="d-flex justify-content-between">
              <Col>
                <ReCAPTCHA sitekey={window.HGGlobal?.recaptchaSiteKey || "key"} onChange={recaptchaChange} />
              </Col>
              <Col className="d-flex justify-content-end">
                <div className="d-flex align-items-end">
                  <ShowStatus status={status} />
                  <Button variant="danger" className="ms-2" onClick={() => setRegStep(Step.Init)}>
                    {t("tour.button.back")}
                  </Button>
                  <Button variant="primary" className="ms-2" onClick={handleSubmit} disabled={!recaptchaValidated}>
                    {t("general.submit")}
                  </Button>
                </div>
              </Col>
            </Row>
          </Card.Footer>
        </Card>
      );
    case Step.Finish:
      return <p className="fs-4">{t("circuit.will-contact")}</p>;
  }
}
