import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Col, Container, Modal, Navbar, Row, Spinner } from "react-bootstrap";
import { CheckCircle } from "react-bootstrap-icons";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import { congregationApi } from "../../api/cong";
import QueryKeys from "../../api/queryKeys";
import logo from "../../assets/hourglass-logo-brown.svg";
import { HGBugsnagNotify } from "../../helpers/bugsnag";
import { isAppleICloudDomain } from "../../helpers/email";

import { useSearchParams } from "react-router";
import { getErrorType, getStatusCode } from "../../helpers/errors";
import { checkPassword } from "../../helpers/password";
import { DuplicateMessage, RegisterCong } from "../../types/register/registerCong";
import { RegistrationModel } from "../../types/register/registerModel";
import { RegistrationState, RegistrationType, Step } from "../../types/register/registerState";
import { RegisterUser } from "../../types/register/registerUser";
import { Warning } from "../alerts";
import { StateTimeoutModal, StateTimeoutSeconds } from "../login";
import { getGUID } from "../scheduling/wm/wm";
import CongInfo from "./congInfo";
import { UserInfo } from "./userInfo";

enum RegistrationQueryParams {
  CongShare = "congShare",
  CongNumber = "congNumber",
  CongName = "congName",
}

export function CongRegistration() {
  const { t } = useTranslation();
  return (
    <Container fluid="md">
      <Navbar>
        <Navbar.Brand>
          <img src={logo} className="App-logo" alt="logo" />
          {t("register.heading")}
        </Navbar.Brand>
      </Navbar>
      <RegistrationStep />
    </Container>
  );
}

function RegistrationStep() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [regState, setRegState] = useState<RegistrationState>({
    user: {
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      googleLoggedIn: false,
      googleAuth: true,
      appleAuth: false,
      appleLoggedIn: false,
      requireApple: false,
      gRecaptchaSubmitted: false,
      gRecaptchaSuccess: false,
      weakPassword: false,
      invalidEmail: false,
      requireGoogle: false,
    },
    cong: {
      //initialize anything we can
      shareText: searchParams.get(RegistrationQueryParams.CongShare) || "",
      shareTextInvalid: false,
      approved: false,
      countriesId: 0,
      timezonesId: 0,
      localesId: 0,
      congNumInvalid: false,
      number: Number(searchParams.get(RegistrationQueryParams.CongNumber)) || 0,
      name: searchParams.get(RegistrationQueryParams.CongName) || "",
    },
  });

  const [regStep, setRegStep] = useState(Step.Init);
  const [formValidated, setFormValidated] = useState(false);
  const [duplicateMessage, setDuplicateMessage] = useState("");
  const [duplicateSendError, setDuplicateSendError] = useState(false);
  const [showCongConfirmModal, setShowCongConfirmModal] = useState(false);
  const [showTimeoutModal, setShowTimeoutModal] = useState(false);
  const [regErrorMessage, setRegErrorMessage] = useState("");

  useEffect(() => {
    const timer = setTimeout(() => setShowTimeoutModal(true), StateTimeoutSeconds * 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    if (
      regStep === Step.Finish ||
      regStep === Step.CongDuplicate ||
      regStep === Step.CongDuplicateEmailSent ||
      regStep === Step.EmailDuplicate
    ) {
      // scroll to the top so we ensure that everything is visible
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [regStep]);

  const getRegType = (user: RegisterUser): RegistrationType => {
    if (user.googleLoggedIn) return RegistrationType.Google;
    if (user.appleLoggedIn) return RegistrationType.Apple;
    return RegistrationType.UserPass;
  };
  const getModelFromState = (): RegistrationModel => {
    const model: RegistrationModel = {
      email: regState.user.email,
      firstName: regState.user.firstName,
      lastName: regState.user.lastName,
      congNumber: regState.cong.number,
      congName: regState.cong.name || "",
      congShareText: regState.cong.shareText,
      regType: getRegType(regState.user),
      countriesId: regState.cong.countriesId,
      timezonesId: regState.cong.timezonesId,
      localesId: regState.cong.localesId,
      state: window.HGGlobal.state,
    };

    if (model.regType === RegistrationType.UserPass) {
      model.password = regState.user.password;
    }

    return model;
  };

  const validate = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    const validity = form.checkValidity();
    setFormValidated(true);

    //clear out anything which comes from server validation
    handleCongChange("shareTextInvalid", false);

    if (!validity) return;

    const regType = getRegType(regState.user);

    //above checks if fields are present. we also need to ensure we have either a recaptcha or a google login
    if (regType === RegistrationType.UserPass && !regState.user.gRecaptchaSuccess) return;

    if (regType === RegistrationType.UserPass) {
      // someone is trying to register with username & password when we require oauth for their email address
      if (regState.user.requireApple || regState.user.requireGoogle) return;
      if (regState.user.email.trim().toLowerCase().endsWith("@jwpub.org")) {
        handleUserChange("invalidEmail", true);
        return;
      }
      //check password strength
      if (regState.user.password != null) {
        const score = await checkPassword(regState.user.password);
        if (score < 2) {
          handleUserChange("weakPassword", true);
          return;
        }
      } else {
        return;
      }
    }

    // everything is good: show the confirmation modal
    if (!regState.cong.shareText.includes("jw.org=skip")) {
      setShowCongConfirmModal(true);
    } else {
      await doRegistration();
    }
  };

  const doRegistration = async () => {
    setRegErrorMessage("");
    try {
      await congregationApi.register(getModelFromState());
      setRegStep(Step.Finish);
    } catch (err: any) {
      switch (await getErrorType(err)) {
        case "congShare":
          handleCongChange("shareTextInvalid", true);
          break;
        case "congDuplicate":
          setRegStep(Step.CongDuplicate);
          break;
        case "emailDuplicate":
          setRegStep(Step.EmailDuplicate);
          break;
        case "missingState":
          window.location.replace(window.location.href);
          break;
        default:
          console.error("cong reg", err);
          setRegErrorMessage(t("form.error.try-later"));
          HGBugsnagNotify("doRegistration", err);
      }
    }
  };

  const handleCongChange = (name: keyof RegisterCong, value: any) => {
    const newCong: RegisterCong = { ...regState.cong, [name]: value };
    setRegState({ ...regState, cong: newCong });
  };

  const handleUserChange = (name: keyof RegisterUser, value: any) => {
    const newUser: RegisterUser = { ...regState.user, [name]: value };

    if (name === "email" && typeof value === "string") {
      // force them to use google signin if they have a gmail address, and apple sign in if they have an icloud address
      if (value.trim().toLowerCase().endsWith("@gmail.com")) newUser.requireGoogle = true;
      else if (isAppleICloudDomain(value)) newUser.requireApple = true;
    }

    setRegState({ ...regState, user: newUser });
  };

  const handleFormValidatedChange = (validated: boolean) => {
    setFormValidated(validated);
  };

  const duplicateEmail = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    //send the message to the back end
    //it will add email.cong-already-registered as a header, then include the quoted/sanitized content
    //and send it to each admin
    const form = e.currentTarget;
    if (!form.checkValidity()) return;

    const dupMessage: DuplicateMessage = {
      state: window.HGGlobal.state,
      message: duplicateMessage,
    };
    try {
      await congregationApi.registerDuplicateMessage(dupMessage);
      setRegStep(Step.CongDuplicateEmailSent);
    } catch (error: any) {
      console.error("error sending message", error);
      setDuplicateSendError(true);
    }
  };

  const updateDuplicateMessage = (e: any) => {
    setDuplicateMessage(e.currentTarget.value);
  };

  switch (regStep) {
    case Step.Init:
      return (
        <div>
          <Row>
            <Col md={6} xs={12}>
              <p>{t("register.already-using")}</p>
            </Col>
          </Row>
          <Row>
            <Col md={6} xs={12}>
              <Button variant="primary" className="me-2" onClick={() => setRegStep(Step.AlreadyRegistered)}>
                {t("popup.confirm.button.yes")}
              </Button>
              <Button variant="primary" onClick={() => setRegStep(Step.Info)}>
                {t("popup.confirm.button.no")}
              </Button>
            </Col>
          </Row>
        </div>
      );
    case Step.AlreadyRegistered:
      return <p>{t("register.only-once")}</p>;
    case Step.Info:
      return (
        <div>
          <StateTimeoutModal show={showTimeoutModal} setShow={setShowTimeoutModal} />
          <ConfirmModal
            show={showCongConfirmModal}
            setShow={setShowCongConfirmModal}
            shareText={regState.cong.shareText}
            onSubmit={doRegistration}
            errorMessage={regErrorMessage}
          />
          <Form onSubmit={validate}>
            <CongInfo cong={regState.cong} onCongChange={handleCongChange} formValidated={formValidated} />
            <UserInfo
              user={regState.user}
              onUserChange={handleUserChange}
              formValidated={formValidated}
              onValidatedChange={handleFormValidatedChange}
              requireGoogleSignin={regState.user.requireGoogle}
              requireAppleSignin={regState.user.requireApple}
            />
            <Button variant="primary" className="mt-3 mb-3" type="submit">
              {t("login.link.sign-up")}
            </Button>
          </Form>
        </div>
      );
    case Step.Finish:
      if (regState.user.googleLoggedIn || regState.user.appleLoggedIn) {
        window.location.href = "/";
        return null;
      }
      return (
        <div>
          {/*send them to sign in if google; show message about validation if email*/}
          {t("register.success.email-sent")}
        </div>
      );
    case Step.CongDuplicate:
      return (
        <div>
          <Form onSubmit={duplicateEmail}>
            <Form.Group controlId="duplicateMessage">
              <Form.Label>{t("register.error.duplicate-send-message")}</Form.Label>
              <Form.Control
                required
                as="textarea"
                name="duplicateMessage"
                rows={6}
                value={duplicateMessage}
                onChange={updateDuplicateMessage}
              />
            </Form.Group>
            {duplicateSendError && <p>{t("inviteaccept.unknown-error.body")}</p>}
            <Button variant="primary" className="mt-3 mb-3" type="submit">
              {t("report.send")}
            </Button>
          </Form>
        </div>
      );
    case Step.CongDuplicateEmailSent:
      return (
        <div>
          {t("sendreminders.success.email-sent")}
          <CheckCircle className="success" size={48} />
        </div>
      );
    case Step.EmailDuplicate:
      //when the email address (entered or via google sign in) is already set up for hourglass login
      return <div>{t("register.already-registered.0")}</div>;
  }
}

const ConfirmModal = (props: {
  show: boolean;
  setShow: (show: boolean) => void;
  shareText: string;
  onSubmit: (e: any) => Promise<void>;
  errorMessage?: string;
}) => {
  const { t } = useTranslation();
  const handleClose = () => {
    props.setShow(false);
    setError("");
  };
  const [error, setError] = useState("");

  const guid = getGUID(props.shareText);

  const jworgQuery = useQuery({
    queryKey: [QueryKeys.CongGUID, guid],
    queryFn: () => congregationApi.getCongInfoFromJWOrgPublic(guid),
    enabled: props.show,

    retry: (failureCount, err) => {
      switch (getStatusCode(err)) {
        case 404:
          return false;
      }
      return failureCount < 2;
    },
  });

  useEffect(() => {
    if (props.errorMessage) setError(props.errorMessage);
  }, [props.errorMessage]);

  useEffect(() => {
    if (!jworgQuery.isError) {
      setError("");
      return;
    }
    if (getStatusCode(jworgQuery.error) === 404) {
      setError(t("warning.cong-not-found"));
    } else {
      setError(t("general.unknown-error"));
    }
  }, [jworgQuery.isError, jworgQuery.error, t]);

  const body = () => {
    if (jworgQuery.isLoading) {
      return <Spinner />;
    }

    if (error) {
      return <div className="warn_text">{error}</div>;
    }

    return (
      <div>
        <p className="h3">{t("schedules.weekend.your-congregation")}</p>
        <Warning text={jworgQuery.data?.properties.orgName ?? ""} className="fw-bold" />
      </div>
    );
  };

  const footer = () => {
    if (error) {
      return (
        <Button variant="primary" onClick={handleClose}>
          {t("popup.error.button.ok")}
        </Button>
      );
    }

    if (jworgQuery.data) {
      return (
        <>
          <Button variant="secondary" onClick={handleClose}>
            {t("popup.confirm.button.no")}
          </Button>
          <Button variant="primary" onClick={props.onSubmit}>
            {t("popup.confirm.button.yes")}
          </Button>
        </>
      );
    }
  };

  return (
    <Modal show={props.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t("popup.confirm.title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body()}</Modal.Body>
      <Modal.Footer>{footer()}</Modal.Footer>
    </Modal>
  );
};
