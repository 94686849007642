import { ChangeEvent, FormEvent, useRef, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { XCircleFill } from "react-bootstrap-icons";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { authApi } from "../api/auth";
import logo from "../assets/hourglass-logo-brown.svg";
import { checkPassword } from "../helpers/password";
import { signOut } from "../helpers/util";
import { ForgotPWChangeRequest } from "../types/forgotpw";
import { HGBugsnagNotify } from "../helpers/bugsnag";
import { getErrorType, getStatusCode } from "../helpers/errors";

type ForgotPWParams = {
  code: string;
};

//N.B. this is an unauthenticated component
export default function ForgotPWCode() {
  const { code } = useParams() as ForgotPWParams;
  const { t } = useTranslation();
  const [pass, setPass] = useState("");
  const [pass2, setPass2] = useState("");
  const [recaptchaResponse, setRecaptchaResponse] = useState("");
  const formRef = useRef<HTMLFormElement>(null);
  const [haveError, setHaveError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const recaptchaChange = (value: string | null) => {
    setRecaptchaResponse(value || "");
  };

  const changePass = async (e: ChangeEvent<HTMLInputElement>) => {
    const target = e.currentTarget;
    const value = target.value;
    setPass(value);
    const score = await checkPassword(value);
    const validity = score < 2 ? t("password-error.insecure") : "";
    target.setCustomValidity(validity);
  };

  const changePass2 = (e: ChangeEvent<HTMLInputElement>) => {
    const target = e.currentTarget;
    const value = target.value;
    setPass2(value);
    const validity = value === pass ? "" : t("password-error.no-match");
    target.setCustomValidity(validity);
  };

  const validatePassword = () => {
    const passForm = formRef.current;
    passForm?.reportValidity();
    return passForm?.checkValidity();
  };

  const doReset = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    setHaveError(false);
    setErrorMessage("");
    if (!validatePassword() || !recaptchaResponse) {
      return;
    }

    const form = e.currentTarget;
    if (!form.checkValidity()) {
      return;
    }

    const req: ForgotPWChangeRequest = {
      code: code,
      password: pass,
      recaptchaResponse: recaptchaResponse,
    };

    try {
      await authApi.forgotPasswordChange(req);
      signOut(true);
    } catch (err: any) {
      console.error("error changing password", err);
      HGBugsnagNotify("changePassword", err);
      setHaveError(true);

      let message: string = "";
      const statusCode = getStatusCode(err);
      if (statusCode === 403) {
        message = t("forgot-password.invalid-code");
      } else if (statusCode === 429) {
        message = t("form.error.try-later");
      } else {
        switch (await getErrorType(err)) {
          case "recaptcha":
            message = "ReCaptcha";
            break;
          case "google":
            message = t("login.sign-in-apple");
            break;
          case "apple":
            message = t("login.modal.body.reset-password.google");
            break;
        }
      }

      setErrorMessage(message);
    }
  };

  return (
    <Container fluid="md">
      <Row className="justify-content-center mt-3 mb-3">
        <Col lg={3} md={4} sm={5}>
          <img src={logo} className="App-logo" alt="logo" />
          <h5 className="App-logo-text">{t("forgotpw.email.subject")}</h5>
        </Col>
      </Row>

      <Form ref={formRef} onSubmit={doReset}>
        <Row className="justify-content-center">
          <Col lg={3} md={4} sm={5}>
            <Form.Group controlId="pass">
              <Form.Label>{t("new-password.label.new-password")}</Form.Label>
              <Form.Control required type="password" value={pass} onChange={changePass} />
            </Form.Group>
          </Col>
        </Row>

        <Row className="justify-content-center mt-1">
          <Col lg={3} md={4} sm={5}>
            <Form.Group controlId="pass2">
              <Form.Label>{t("new-password.label.new-password")}</Form.Label>
              <Form.Control required type="password" value={pass2} onChange={changePass2} />
            </Form.Group>
          </Col>
        </Row>

        <Row className="justify-content-center mt-2">
          <Col lg={3} md={4} sm={5}>
            <ReCAPTCHA sitekey={window.HGGlobal?.recaptchaSiteKey || "key"} onChange={recaptchaChange} />
            <Button className="mt-3" variant="primary" type="submit" disabled={!pass || !pass2 || !recaptchaResponse}>
              {t("form.reset-password")}
            </Button>
            {haveError && <XCircleFill size={24} color="red" className="ms-2 mt-3" />}
            {haveError && !!errorMessage && <p className="text-danger">{errorMessage}</p>}
          </Col>
        </Row>
      </Form>
    </Container>
  );
}
