import { NavigateFunction } from "react-router";

export class HGNavigate {
  static navigateInstance: NavigateFunction | null = null;

  static push(url: string) {
    if (!HGNavigate.navigateInstance) return;
    HGNavigate.navigateInstance(url);
  }
}
