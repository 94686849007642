import { useContext, useState } from "react";
import { Button, Col, Modal, Row, Table } from "react-bootstrap";
import {
  HouseFill,
  Plus,
  SortAlphaDown,
  SortAlphaDownAlt,
  SortNumericDown,
  SortNumericDownAlt,
} from "react-bootstrap-icons";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import QueryKeys from "../../../api/queryKeys";
import { weekendApi } from "../../../api/weekend";
import { HGContext } from "../../../helpers/globals";
import { PublicTalk } from "../../../types/scheduling/weekend";
import { QueryStatus } from "../../queryStatus";
import { useTranslation } from "react-i18next";
import { HourglassDatePicker, MenuType } from "../date";
import { dateToString } from "../../../helpers/dateHelpers";
import { getStatusCode } from "../../../helpers/errors";
import { ISODateString } from "../../../types/date";
import { Permission } from "../../../types/permission";
import { SearchFilter } from "../../searchFilter";
import { DisabledPublicTalkIndicator, outlineDisabled, weekendLocalizedDateRange } from "./wm_common";
import { HGBugsnagNotify } from "../../../helpers/bugsnag";

const getLocalTalkMap = (localTalks: PublicTalk[], allTalks: PublicTalk[]) => {
  const talkMap = new Map<number, boolean>();

  for (const t of allTalks) {
    talkMap.set(t.number, false);
  }
  for (const t of localTalks) {
    talkMap.set(t.number, true);
  }
  return talkMap;
};

const QueryKeyLastGiven = `${QueryKeys.WMTalks}all_last_given`;

export function WMTalks(props: { langGroupId: number }) {
  const [desc, setDesc] = useState(false);
  const [sortBy, setSortBy] = useState<keyof PublicTalk>("number"); // number | title | last_date_given
  const [filterBy, setFilterBy] = useState("");
  // lastGivenModal holds the talk number if someone clicks + to add a last given entry
  const [lastGivenModal, setLastGivenModel] = useState(0);
  const { t, i18n } = useTranslation();

  const ctx = useContext(HGContext);
  const myCong = ctx.globals.cong;
  const allTalksQuery = useQuery({
    queryKey: [QueryKeyLastGiven, props.langGroupId],

    queryFn: () => weekendApi.getTalks(true, props.langGroupId),
  });
  const localTalksQuery = useQuery({
    queryKey: [`${QueryKeys.WMTalks}local`],

    queryFn: () => weekendApi.getTalksByCong(myCong!.id, false, props.langGroupId),
  });

  const queryStatus = QueryStatus(allTalksQuery, localTalksQuery);
  if (queryStatus !== null) return queryStatus;

  const localTalkMap = getLocalTalkMap(localTalksQuery.data ?? [], allTalksQuery.data ?? []);
  const nowIso: ISODateString = dateToString(new Date());

  const row = allTalksQuery.data
    ?.sort((a, b) => {
      if (sortBy === "number") {
        // sort talk number numerically, not lexically
        return desc ? b.number - a.number : a.number - b.number;
      }
      const c = a[sortBy] === "n/a" ? "0" : a[sortBy];
      const d = b[sortBy] === "n/a" ? "0" : b[sortBy];
      if (String(c).replaceAll(/[“”"'`]+/g, "") < String(d).replaceAll(/[“”"'`]+/g, "")) return desc ? 1 : -1;
      return desc ? -1 : 1;
    })
    .filter((t) => {
      return `${t.number}` === filterBy || t.title.toLocaleLowerCase().includes(filterBy.toLocaleLowerCase());
    })
    .map((t) => {
      const disabledTalk = outlineDisabled(t.number, nowIso);
      return (
        <tr key={t.number}>
          <td>{t.number}</td>
          <td>
            {t.title} {localTalkMap.get(t.number) && <HouseFill className="text-muted" />}
            {!!disabledTalk && <DisabledPublicTalkIndicator talkDate={disabledTalk} />}
          </td>
          <td>
            {t.last_date_given
              ? weekendLocalizedDateRange(t.last_date_given, i18n.language)
              : ctx.globals.permissions.has(Permission.UpdateWeekendSchedules) && (
                  <Button size="sm" variant="secondary" onClick={() => setLastGivenModel(t.number)}>
                    <Plus />
                  </Button>
                )}
          </td>
        </tr>
      );
    });
  return (
    <>
      <LastGivenModal
        show={!!lastGivenModal}
        talkNumber={lastGivenModal}
        close={() => setLastGivenModel(0)}
        langGroupId={props.langGroupId}
      />
      <Row className="text-center my-3">
        <Col>
          <SearchFilter className="ms-5" setFilter={setFilterBy} filter={filterBy} />
        </Col>
        <Col>
          <p className="text-muted">
            <HouseFill /> {t("schedules.weekend.given-by-local-speaker")}
          </p>
        </Col>
      </Row>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th
              role="button"
              onClick={() => {
                setDesc(sortBy === "number" ? !desc : false);
                setSortBy("number");
              }}
            >
              # {sortBy === "number" && (desc ? <SortNumericDownAlt /> : <SortNumericDown />)}
            </th>
            <th
              role="button"
              onClick={() => {
                setDesc(sortBy === "title" ? !desc : false);
                setSortBy("title");
              }}
            >
              {t("schedules.weekend.talk-title")}{" "}
              {sortBy === "title" && (desc ? <SortAlphaDownAlt /> : <SortAlphaDown />)}
            </th>
            <th
              role="button"
              onClick={() => {
                setDesc(sortBy === "last_date_given" ? !desc : false);
                setSortBy("last_date_given");
              }}
            >
              {t("schedules.weekend.last-scheduled")}
              {sortBy === "last_date_given" && (desc ? <SortAlphaDownAlt /> : <SortAlphaDown />)}
            </th>
          </tr>
        </thead>
        <tbody>{row}</tbody>
      </Table>
    </>
  );
}

function LastGivenModal(props: { show: boolean; talkNumber: number; close: () => void; langGroupId: number }) {
  const { t } = useTranslation();
  const [date, setDate] = useState(dateToString(new Date()));
  const [saveError, setSaveError] = useState("");
  // the api takes an array, but we are just setting one at a time here
  const lastGivenMutation = useMutation({
    mutationFn: (pt: PublicTalk) => weekendApi.setLastGiven([pt], props.langGroupId),
  });
  const queryClient = useQueryClient();

  const handleClose = () => {
    setSaveError("");
    props.close();
  };

  const changeDate = (newDate: ISODateString) => {
    setDate(newDate);
    setSaveError("");
  };

  const setLastGiven = async () => {
    const pt: PublicTalk = {
      title: "",
      last_date_given: date,
      number: props.talkNumber,
    };

    try {
      const updatedPts = await lastGivenMutation.mutateAsync(pt);
      if (updatedPts.length === 1) {
        const newPt = updatedPts[0]!;
        queryClient.setQueryData<PublicTalk[]>([QueryKeyLastGiven, props.langGroupId], (old) => {
          return old
            ? old.map((qpt) =>
                qpt.number === newPt.number
                  ? {
                      ...qpt,
                      last_date_given: newPt.last_date_given,
                    }
                  : qpt,
              )
            : [newPt];
        });
      }
      handleClose();
    } catch (err: any) {
      switch (getStatusCode(err)) {
        case 409:
          setSaveError(t("schedules.weekend.talk-already-scheduled"));
          break;
        default:
          setSaveError(t("popup.error.title.generic"));
          console.error("error setting last given date for talk", err);
          HGBugsnagNotify("wmTalkLastGiven", err);
      }
    }
  };

  return (
    <Modal show={props.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t("schedules.weekend.last-scheduled")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <HourglassDatePicker
          menuType={MenuType.Day}
          date={date}
          max={dateToString(new Date())}
          onDateChange={changeDate}
        />
      </Modal.Body>
      <Modal.Footer>
        {!!saveError && <p className="text-danger bold">{saveError}</p>}
        <Button variant="secondary" onClick={props.close}>
          {t("general.cancel")}
        </Button>
        <Button variant="primary" onClick={setLastGiven} disabled={!!saveError}>
          {t("general.save")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
