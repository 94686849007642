import { Nav } from "react-bootstrap";
import logo from "../../assets/hourglass-logo-brown.svg";
import { useTranslation } from "react-i18next";
import { Link } from "react-router";
import { useContext } from "react";
import { HGContext } from "../../helpers/globals";
import { Permission } from "../../types/permission";
import { NavLoggedInUser } from "./loggedInUser";

export function HourglassUserNav() {
  const { t } = useTranslation();
  const ctx = useContext(HGContext);

  const hasAttendance = ctx.globals.permissions.has(Permission.UpdateAttendance);

  return (
    <Nav className="top-nav d-flex p-0">

      <Link className="me-auto" to="/">
        <div className="brand-label overflow-hidden p-2 d-flex">
            <img src={logo} className="top-nav-logo" alt="Hourglass logo" />
        </div>
      </Link>

      {hasAttendance && (
        <Nav.Link as={Link} to="/attendance">
          {t("nav.congregation.attendance")}
        </Nav.Link>
      )}

      <NavLoggedInUser />
  
    </Nav>
  );
}
