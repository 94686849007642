import { useTranslation } from "react-i18next";
import { Nav } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import HourglassGlobals from "../../helpers/globals";
import { BsFillSunFill, BsMoonStarsFill } from "react-icons/bs";
import { signOut } from "../../helpers/util";
import { LanguagePicker } from "../languagePicker";
import { Link } from "react-router";
import { BsTranslate } from "react-icons/bs";
import { ChangeEvent, useEffect } from "react";
import { ReactSVG } from "react-svg";
import userIcon from "../../assets/user.svg";
import { setTheme } from "../../helpers/setTheme";
import { Permission } from "../../types/permission";

export function NavLoggedInUser() {
  return (
    <Nav>
      <DropdownButton
        title={
          <span>
            <ReactSVG
              src={userIcon}
              style={{ width: "2rem", height: "2rem", margin: "-1rem 0", display: "inline-block" }}
            />
            <span className="mx-1  d-none d-sm-inline">{HourglassGlobals.authUser?.displayName ?? ""}</span>
          </span>
        }
        id="collasible-nav-dropdown"
        variant="none"
        align="end"
        className="dropdown-bounded h-100"
      >
        <NavLoggedInUserItems />
      </DropdownButton>
    </Nav>
  );
}

//these are broken out for re-use in the mobile top bar dropdown
export function NavLoggedInUserItems() {
  const { t } = useTranslation();

  const theme = document.documentElement.getAttribute("data-bs-theme");

  const themeToggleChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setTheme(isChecked ? "dark" : "light", true);
  };

  useEffect(() => {
    const el = document.getElementById("theme-toggle") as HTMLInputElement;
    if (el) {
      el.checked = theme === "dark";
    }
  }, [theme]);

  return (
    <>
      <Dropdown.ItemText>
        <b>
          <big>{HourglassGlobals.authUser?.displayName ?? ""}</big>
        </b>
        <br />
        <p className="font-monospace">
          <small>{HourglassGlobals.authUser?.email ?? ""}</small>
        </p>
        {HourglassGlobals.cong?.name ?? ""}
      </Dropdown.ItemText>
      <Dropdown.Divider />

      {HourglassGlobals.permissions.has(Permission.UpdateUsers) && (
        <Dropdown.Item as={Link} to={`/user/edit/${HourglassGlobals.authUser?.id}`}>
          {t("general.edit")}
        </Dropdown.Item>
      )}

      <Dropdown.Item as={Link} to={`/user/auth/${HourglassGlobals.authUser?.id}`}>
        {t("userinfo.tab.authentication.0")}
      </Dropdown.Item>

      <Dropdown.Item href="" onClick={() => signOut()}>
        {t("form.button.signout")}
      </Dropdown.Item>

      <Dropdown.Divider />

      <Dropdown.ItemText className="d-flex gap-2 align-items-center">
        <BsFillSunFill />
        <div className="form-check form-switch m-0 p-0">
          <input id="theme-toggle" className="form-check-input ms-0" onChange={themeToggleChanged} type="checkbox" />
        </div>
        <BsMoonStarsFill />
      </Dropdown.ItemText>

      <Dropdown.Divider />

      <Dropdown.Header>
        <BsTranslate /> {t("congprofile.language")}
      </Dropdown.Header>
      <LanguagePicker />
    </>
  );
}
