import { useContext, useState } from "react";
import { Button, Nav } from "react-bootstrap";
import { FaBell } from "react-icons/fa";
import { Link } from "react-router";

import logo from "../../assets/hourglass-logo-brown.svg";
import HourglassGlobals, { HGContext } from "../../helpers/globals";
import { UserSearch } from "../userSearch";
import { WarningModal } from "../warningModal";
import { NavLoggedInUser } from "./loggedInUser";

//this is the sidebar navigation for desktop/larger screens
function NewNav() {
  const ctx = useContext(HGContext);
  const [showWarnings, setShowWarnings] = useState(false);
  const clickShowWarnings = () => {
    ctx.setGlobals({ ...ctx.globals, warningsDismissed: false });
    setShowWarnings(true);
  };

  return (
    <Nav className="top-nav d-flex p-0 d-print-none flex-row">
      {showWarnings && <WarningModal />}

      <Link to="/">
        <div className="brand-label overflow-hidden p-2 d-flex d-md-none">
          <img src={logo} className="top-nav-logo" alt="Hourglass logo" />
        </div>
      </Link>

      <UserSearch />

      {HourglassGlobals.haveWarnings() && (
        <Button className="alert-btn" variant="none" onClick={clickShowWarnings}>
          <FaBell />
          <span className="badge rounded-pill badge-notification bg-danger">{HourglassGlobals.getWarningsCount()}</span>
        </Button>
      )}

      <NavLoggedInUser />
    </Nav>
  );
}

export default NewNav;
