import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ChangeEvent, FormEvent, useContext, useEffect, useState } from "react";
import { Badge, Button, Col, Dropdown, Form, Row } from "react-bootstrap";
import { Unlock } from "react-bootstrap-icons";
import DropdownItem from "react-bootstrap/DropdownItem";
import { useTranslation } from "react-i18next";
import { congregationApi, useCongSettingsSuspense } from "../../../api/cong";
import { usePrivilegeMap } from "../../../api/meetings";
import QueryKeys from "../../../api/queryKeys";
import { useTags } from "../../../api/tags";
import { HGBugsnagNotify } from "../../../helpers/bugsnag";
import HourglassGlobals, { HGContext } from "../../../helpers/globals";
import { getStoredLangGroupId, languageGroupName } from "../../../helpers/langGroups";
import { userCompare } from "../../../helpers/user";
import { MaxSongNumber, numberArrayRange } from "../../../helpers/util";
import { getWMTemplateHTML } from "../../../helpers/wmTemplates";
import { Cong } from "../../../types/cong";
import { Permission } from "../../../types/permission";
import { UserPrivileges } from "../../../types/scheduling/privileges";
import { CongSetting, CongSettingMap, CongSettings } from "../../../types/scheduling/settings";
import { PseudoTag, Tag, TagCategory, TagDefault } from "../../../types/scheduling/tags";
import User from "../../../types/user";
import { TemplateTextEditor } from "../../common/tiptap/TemplateEditor";
import { TemplateVariable } from "../../common/tiptap/TemplateVariable";
import { TagsDDM, tagDefaultToTag, tagToDefault, terr_checkout_default_tags } from "../../congregation/tags";
import { SaveResult, SaveStatus } from "../../saveStatus";
import { HGPageTitle, InfoPopover } from "../../utils";
import { UserMap } from "../common";
import { LanguageGroupDDM } from "../languageGroup";
import useRunOnce from "../../../helpers/useRunOnce";

export function canUpdateCongSettings(): boolean {
  return HourglassGlobals.permissions.has(Permission.UpdateCongregation);
}

export default function Settings() {
  const { t } = useTranslation();
  const [saveResult, setSaveResult] = useState<SaveResult>(SaveResult.None);
  const [langGroupId, setLangGroupId] = useState(getStoredLangGroupId());
  const saveSettingsMutation = useMutation({
    mutationFn: (settings: CongSettings) => congregationApi.setSettings(settings, langGroupId),
  });
  const queryClient = useQueryClient();
  const ctx = useContext(HGContext);
  const hostCong = ctx.globals.cong!;

  const congSettingsQuery = useCongSettingsSuspense(langGroupId);
  const [settings, setSettings] = useState(congSettingsQuery.data);

  const handleReset = () => {
    setSettings(congSettingsQuery.data);
  };

  // we only want this to run when the language group changes
  // TODO look at this and see if we should include the full dependency array
  useEffect(() => {
    setSettings(congSettingsQuery.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [langGroupId]);

  const handleUpdate = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const form = e.currentTarget;
    if (!form.checkValidity()) return;

    try {
      const resp = await saveSettingsMutation.mutateAsync(settings);
      setSaveResult(SaveResult.Success);
      await Promise.all([
        queryClient.setQueryData([QueryKeys.CongregationSettings, langGroupId], resp),
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.WMCSchedules],
        }),
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.AVAttendantAssignment],
        }),
      ]);
    } catch (err: any) {
      setSaveResult(SaveResult.Failure);
      console.error("error saving scheduling settings", err);
      HGBugsnagNotify("schedulingSettings", err);
    }
  };

  return (
    <div>
      <div className="d-flex flex-row justify-content-between align-items-end mb-4">
        <HGPageTitle title={t("schedules.settings.title")} />
        <LanguageGroupDDM langGroupId={langGroupId} setLangGroupId={setLangGroupId} />
      </div>
      <Form className="my-4 d-flex flex-column gap-4" onSubmit={handleUpdate}>
        <div className="card">
          <div className="card-body">
            <h5>{t("conganalysis.attendance.weekend")}</h5>
            <hr className="mt-3" />
            <SettingsWM settings={settings} setSettings={setSettings} myCong={hostCong} lgroup={langGroupId} />
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <h5>{t("conganalysis.attendance.midweek")}</h5>
            <hr className="mt-3" />
            <SettingsMM settings={settings} setSettings={setSettings} showCOName={false} />
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <h5>{t("schedules.ava.title")}</h5>
            <hr className="mt-3" />
            <SettingsAVA settings={settings} setSettings={setSettings} />
          </div>
        </div>

        {langGroupId === hostCong.id && (
          <>
            <div className="card">
              <div className="card-body">
                <h5>{t("schedules.cleaning.title")}</h5>
                <hr className="mt-3" />
                <SettingsCleaning settings={settings} setSettings={setSettings} />
              </div>
            </div>

            <div className="card">
              <div className="card-body">
                <h5>{t("schedules.public-witnessing.title")}</h5>
                <hr className="mt-3" />
                <SettingsPW settings={settings} setSettings={setSettings} />
              </div>
            </div>
          </>
        )}

        <div className="mt-4">
          <Button variant="secondary" onClick={handleReset} disabled={!canUpdateCongSettings()}>
            {t("general.reset")}
          </Button>
          <Button
            variant="primary"
            type="submit"
            className="ms-1 me-1"
            disabled={settings === congSettingsQuery.data || !canUpdateCongSettings()}
          >
            {t("general.save")}
          </Button>
          <SaveStatus saveResult={saveResult} saveKey="sched_settings" setSaveResult={setSaveResult} />
        </div>
      </Form>
    </div>
  );
}

export function DDMstrings(props: {
  settings: CongSettings;
  setSettings: (set: CongSettings) => void;
  settingKey: CongSetting;
  options: { label: string; value: string }[];
  labelSuffix?: string;
  infoMsg?: string;
  coerceToNumber?: boolean;
}) {
  const { t } = useTranslation();
  const myCong = useContext(HGContext).globals.cong!;

  const change = (e: ChangeEvent<HTMLSelectElement>) => {
    const val = props.coerceToNumber ? parseInt(e.currentTarget.value) : e.currentTarget.value;
    props.setSettings({
      ...props.settings,
      [props.settingKey]: val,
    });
  };

  let val = props.settings[props.settingKey];
  // the default for the paper size setting needs to be the country's paper size
  if (!val && props.settingKey === CongSetting.PaperSize) val = myCong.country.paper_size;

  return (
    <Form.Group as={Row} className="mt-1">
      <Form.Label column>
        {t(CongSettingMap[props.settingKey])}
        {props.labelSuffix && " (" + props.labelSuffix + ")"}
        {!!props.infoMsg && <InfoPopover text={props.infoMsg} />}
      </Form.Label>
      <Col>
        <Form.Select value={val as string} onChange={change}>
          {props.options.map((o) => (
            <option key={o.value} value={o.value}>
              {o.label}
            </option>
          ))}
        </Form.Select>
      </Col>
    </Form.Group>
  );
}

export function DDMnumbers(props: {
  settings: CongSettings;
  setSettings: (set: CongSettings) => void;
  settingKey: CongSetting;
  options: number;
  exactOptions?: number[];
  allowZero?: boolean;
  zeroIsBlank?: boolean;
}) {
  const { t } = useTranslation();

  const change = (e: ChangeEvent<HTMLSelectElement>) => {
    const intVal = parseInt(e.currentTarget.value);
    if (isNaN(intVal)) return;
    props.setSettings({
      ...props.settings,
      [props.settingKey]: intVal,
    });
  };

  const options = props.exactOptions ?? numberArrayRange(1, props.options);

  return (
    <Form.Group as={Row} className="mt-1">
      <Form.Label column>{t(CongSettingMap[props.settingKey])}</Form.Label>
      <Col>
        <Form.Select value={(props.settings[props.settingKey] as number) || ""} onChange={change}>
          {props.allowZero && <option key={0} label={props.zeroIsBlank ? "" : "0"} value={0} />}
          {options.map((v, i) => (
            <option key={i} value={v}>
              {v}
            </option>
          ))}
        </Form.Select>
      </Col>
    </Form.Group>
  );
}

export function TextInput(props: {
  settings: CongSettings;
  setSettings: (set: CongSettings) => void;
  settingKey: CongSetting;
  pill?: number;
  placeholder?: string;
  hidden?: boolean;
  minLength?: number;
  maxLength?: number;
  inputType?: string;
  min?: number;
  max?: number;
  disabled?: boolean;
  infoMsg?: string;
}) {
  const { t } = useTranslation();

  return (
    <Form.Group as={Row} className="mt-1" hidden={props.hidden}>
      <Form.Label column>
        {t(CongSettingMap[props.settingKey])}
        {!!props.pill && (
          <Badge pill bg="secondary" className="ms-1">
            {props.pill}
          </Badge>
        )}
        {!!props.infoMsg && <InfoPopover text={props.infoMsg} />}
      </Form.Label>
      <Col>
        <Form.Control
          as="input"
          placeholder={props.placeholder ?? ""}
          value={props.settings[props.settingKey] as string}
          type={props.inputType || "text"}
          minLength={props.minLength ?? 0}
          maxLength={props.maxLength}
          min={props.min}
          max={props.max}
          disabled={props.disabled}
          onChange={(e) => {
            let value = props.inputType === "number" ? Number(e.target.value) : e.target.value;
            if (typeof value === "number" && typeof props.min === "number" && (!value || value < props.min))
              value = props.min;
            props.setSettings({
              ...props.settings,
              [props.settingKey]: value,
            });
          }}
        />
      </Col>
    </Form.Group>
  );
}

export function TextArea(props: {
  settings: CongSettings;
  setSettings: (set: CongSettings) => void;
  settingKey: CongSetting;
  labelIcon?: JSX.Element;
}) {
  const { t } = useTranslation();

  return (
    <Form.Group as={Row} className="mt-1">
      <Form.Label column>
        {t(CongSettingMap[props.settingKey])} {props.labelIcon}
      </Form.Label>
      <Col>
        <Form.Control
          as="textarea"
          rows={4}
          value={props.settings[props.settingKey] as string}
          onChange={(e) => {
            props.setSettings({
              ...props.settings,
              [props.settingKey]: e.target.value,
            });
          }}
        />
      </Col>
    </Form.Group>
  );
}

export function TemplateEditor(props: {
  settings: CongSettings;
  content: string;
  setSettings: (set: CongSettings) => void;
  settingKey: CongSetting;
  variables: TemplateVariable[];
}) {
  const { t } = useTranslation();
  return (
    <Form.Group as={Row} className="mt-1">
      <Form.Label column>{t(CongSettingMap[props.settingKey])}</Form.Label>
      <Col>
        <TemplateTextEditor
          value={props.content}
          variables={props.variables}
          onChange={(value) => {
            props.setSettings({
              ...props.settings,
              [props.settingKey]: value,
            });
          }}
        />
      </Col>
    </Form.Group>
  );
}

export function CheckBox(props: {
  settings: CongSettings;
  setSettings: (set: CongSettings) => void;
  settingKey: CongSetting;
  switchType?: boolean;
  infoMsg?: string;
  disabled?: boolean;
}) {
  const { t } = useTranslation();

  return (
    <Form.Group as={Row} className="mt-1">
      <Form.Label column>
        {t(CongSettingMap[props.settingKey])}
        {!!props.infoMsg && <InfoPopover text={props.infoMsg} />}
      </Form.Label>
      <Col>
        <Form.Check
          as="input"
          className="fs-4"
          disabled={props.disabled === true}
          type={props.switchType ? "switch" : undefined}
          checked={props.settings[props.settingKey] as boolean}
          onChange={(e) => {
            props.setSettings({
              ...props.settings,
              [props.settingKey]: e.target.checked,
            });
          }}
        />
      </Col>
    </Form.Group>
  );
}

export function UserDropdown(props: {
  settings: CongSettings;
  setSettings: (set: CongSettings) => void;
  settingKey: CongSetting;
  privilege: keyof UserPrivileges;
  lgroup: number;
}) {
  const { t } = useTranslation();
  const users = UserMap();
  const privilegeMap = usePrivilegeMap(props.lgroup);

  const currentAssignee = props.settings[props.settingKey];
  const assignee = typeof currentAssignee === "number" ? users.get(currentAssignee) : undefined;

  const setUser = (userId: number | undefined) => {
    const settings = { ...props.settings, [props.settingKey]: userId };
    props.setSettings(settings);
  };

  const privMapData = privilegeMap.data ? privilegeMap.data[props.settingKey] : undefined;
  const possibleUsers = privMapData
    ? privMapData
        .map((userId) => users.get(userId))
        .filter((u): u is User => !!u)
        .sort(userCompare)
    : assignee
      ? [assignee]
      : [];

  return (
    <Form.Group as={Row} className="mt-1">
      <Form.Label column>{t(CongSettingMap[props.settingKey])}</Form.Label>
      <Col>
        <Dropdown className="dropdown-bounded">
          <Dropdown.Toggle variant="secondary">
            {assignee?.displayName || <i>{t("general.none-selected")}</i>}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {!!assignee && (
              <>
                <Dropdown.Item onClick={() => setUser(undefined)}>
                  ❌ <i>{t("schedules.fill.clear")}</i>
                </Dropdown.Item>
                <Dropdown.Divider />
              </>
            )}
            {possibleUsers
              .filter((u) => assignee?.id !== u.id)
              .map((u) => (
                <DropdownItem key={u.id} onClick={() => setUser(u.id)}>
                  {u.displayName}
                </DropdownItem>
              ))}
          </Dropdown.Menu>
        </Dropdown>
      </Col>
    </Form.Group>
  );
}

export const SettingsAVA = (props: { settings: CongSettings; setSettings: (set: CongSettings) => void }) => {
  const { t } = useTranslation();
  return (
    <div>
      <DDMnumbers
        settingKey={CongSetting.NumberOfMicAssignments}
        settings={props.settings}
        setSettings={props.setSettings}
        options={6}
        allowZero
      />
      <DDMnumbers
        settingKey={CongSetting.NumberOfAttendantAssignments}
        settings={props.settings}
        setSettings={props.setSettings}
        options={6}
      />
      <DDMnumbers
        settingKey={CongSetting.NumberOfZoomAttendantAssignments}
        settings={props.settings}
        setSettings={props.setSettings}
        options={3}
        allowZero
      />
      <DDMnumbers
        settingKey={CongSetting.NumberOfSecurityAttendantAssignments}
        settings={props.settings}
        setSettings={props.setSettings}
        options={6}
        allowZero
      />
      <DDMnumbers
        settingKey={CongSetting.NumberOfStageAssignments}
        settings={props.settings}
        setSettings={props.setSettings}
        options={3}
        allowZero
      />
      <DDMnumbers
        settingKey={CongSetting.NumberOfConsoleAssignments}
        settings={props.settings}
        setSettings={props.setSettings}
        options={3}
        allowZero
      />
      <DDMnumbers
        settingKey={CongSetting.NumberOfVideoAssignments}
        settings={props.settings}
        setSettings={props.setSettings}
        options={3}
        allowZero
      />
      <TextInput
        settingKey={CongSetting.VideoLabel1}
        settings={props.settings}
        setSettings={props.setSettings}
        pill={1}
        placeholder={t("schedules.console.video")}
        hidden={props.settings.number_of_video_assignments < 1}
      />
      <TextInput
        settingKey={CongSetting.VideoLabel2}
        settings={props.settings}
        setSettings={props.setSettings}
        pill={2}
        hidden={props.settings.number_of_video_assignments < 2}
      />
      <TextInput
        settingKey={CongSetting.VideoLabel3}
        settings={props.settings}
        setSettings={props.setSettings}
        pill={3}
        hidden={props.settings.number_of_video_assignments < 3}
      />
      <TextInput
        settingKey={CongSetting.ConsoleLabel1}
        settings={props.settings}
        setSettings={props.setSettings}
        pill={1}
        placeholder={t("schedules.console.audio")}
        hidden={props.settings.number_of_console_assignments < 1}
      />
      <TextInput
        settingKey={CongSetting.ConsoleLabel2}
        settings={props.settings}
        setSettings={props.setSettings}
        pill={2}
        hidden={props.settings.number_of_console_assignments < 2}
      />
      <TextInput
        settingKey={CongSetting.ConsoleLabel3}
        settings={props.settings}
        setSettings={props.setSettings}
        pill={3}
        hidden={props.settings.number_of_console_assignments < 3}
      />
      <TextInput
        settingKey={CongSetting.MicLabel1}
        settings={props.settings}
        setSettings={props.setSettings}
        pill={1}
        hidden={props.settings.number_of_mic_assignments < 1}
      />
      <TextInput
        settingKey={CongSetting.MicLabel2}
        settings={props.settings}
        setSettings={props.setSettings}
        pill={2}
        hidden={props.settings.number_of_mic_assignments < 2}
      />
      <TextInput
        settingKey={CongSetting.MicLabel3}
        settings={props.settings}
        setSettings={props.setSettings}
        pill={3}
        hidden={props.settings.number_of_mic_assignments < 3}
      />
      <TextInput
        settingKey={CongSetting.MicLabel4}
        settings={props.settings}
        setSettings={props.setSettings}
        pill={4}
        hidden={props.settings.number_of_mic_assignments < 4}
      />
      <TextInput
        settingKey={CongSetting.MicLabel5}
        settings={props.settings}
        setSettings={props.setSettings}
        pill={5}
        hidden={props.settings.number_of_mic_assignments < 5}
      />
      <TextInput
        settingKey={CongSetting.MicLabel6}
        settings={props.settings}
        setSettings={props.setSettings}
        pill={6}
        hidden={props.settings.number_of_mic_assignments < 6}
      />
      <TextInput
        settingKey={CongSetting.StageLabel1}
        settings={props.settings}
        setSettings={props.setSettings}
        pill={1}
        hidden={props.settings.number_of_stage_assignments < 1}
      />
      <TextInput
        settingKey={CongSetting.StageLabel2}
        settings={props.settings}
        setSettings={props.setSettings}
        pill={2}
        hidden={props.settings.number_of_stage_assignments < 2}
      />
      <TextInput
        settingKey={CongSetting.StageLabel3}
        settings={props.settings}
        setSettings={props.setSettings}
        pill={3}
        hidden={props.settings.number_of_stage_assignments < 3}
      />
      <TextInput
        settingKey={CongSetting.AttendantLabel1}
        settings={props.settings}
        setSettings={props.setSettings}
        pill={1}
        hidden={props.settings.number_of_attendant_assignments < 1}
      />
      <TextInput
        settingKey={CongSetting.AttendantLabel2}
        settings={props.settings}
        setSettings={props.setSettings}
        pill={2}
        hidden={props.settings.number_of_attendant_assignments < 2}
      />
      <TextInput
        settingKey={CongSetting.AttendantLabel3}
        settings={props.settings}
        setSettings={props.setSettings}
        pill={3}
        hidden={props.settings.number_of_attendant_assignments < 3}
      />
      <TextInput
        settingKey={CongSetting.AttendantLabel4}
        settings={props.settings}
        setSettings={props.setSettings}
        pill={4}
        hidden={props.settings.number_of_attendant_assignments < 4}
      />
      <TextInput
        settingKey={CongSetting.AttendantLabel5}
        settings={props.settings}
        setSettings={props.setSettings}
        pill={5}
        hidden={props.settings.number_of_attendant_assignments < 5}
      />
      <TextInput
        settingKey={CongSetting.AttendantLabel6}
        settings={props.settings}
        setSettings={props.setSettings}
        pill={6}
        hidden={props.settings.number_of_attendant_assignments < 6}
      />
      <TextInput
        settingKey={CongSetting.ZoomAttendantLabel1}
        settings={props.settings}
        setSettings={props.setSettings}
        pill={1}
        hidden={props.settings.number_of_zoom_attendant_assignments < 1}
      />
      <TextInput
        settingKey={CongSetting.ZoomAttendantLabel2}
        settings={props.settings}
        setSettings={props.setSettings}
        pill={2}
        hidden={props.settings.number_of_zoom_attendant_assignments < 2}
      />
      <TextInput
        settingKey={CongSetting.ZoomAttendantLabel3}
        settings={props.settings}
        setSettings={props.setSettings}
        pill={3}
        hidden={props.settings.number_of_zoom_attendant_assignments < 3}
      />
      <TextInput
        settingKey={CongSetting.SecurityAttendantLabel1}
        settings={props.settings}
        setSettings={props.setSettings}
        pill={1}
        hidden={props.settings.number_of_security_attendant_assignments < 1}
      />
      <TextInput
        settingKey={CongSetting.SecurityAttendantLabel2}
        settings={props.settings}
        setSettings={props.setSettings}
        pill={2}
        hidden={props.settings.number_of_security_attendant_assignments < 2}
      />
      <TextInput
        settingKey={CongSetting.SecurityAttendantLabel3}
        settings={props.settings}
        setSettings={props.setSettings}
        pill={3}
        hidden={props.settings.number_of_security_attendant_assignments < 3}
      />
      <TextInput
        settingKey={CongSetting.SecurityAttendantLabel4}
        settings={props.settings}
        setSettings={props.setSettings}
        pill={4}
        hidden={props.settings.number_of_security_attendant_assignments < 4}
      />
      <TextInput
        settingKey={CongSetting.SecurityAttendantLabel5}
        settings={props.settings}
        setSettings={props.setSettings}
        pill={5}
        hidden={props.settings.number_of_security_attendant_assignments < 5}
      />
      <TextInput
        settingKey={CongSetting.SecurityAttendantLabel6}
        settings={props.settings}
        setSettings={props.setSettings}
        pill={6}
        hidden={props.settings.number_of_security_attendant_assignments < 6}
      />
      <CheckBox
        switchType
        settingKey={CongSetting.AVAScheduleWeekly}
        settings={props.settings}
        setSettings={props.setSettings}
      />
    </div>
  );
};

export const SettingsMM = (props: {
  settings: CongSettings;
  setSettings: (set: CongSettings) => void;
  showCOName: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <div>
      {props.showCOName && (
        <TextInput
          settingKey={CongSetting.CircuitOverseerName}
          settings={props.settings}
          setSettings={props.setSettings}
        />
      )}
      <TextInput
        settingKey={CongSetting.CircuitOverseerMMTalkTitle}
        settings={props.settings}
        setSettings={props.setSettings}
      />
      <DDMnumbers
        settings={props.settings}
        setSettings={props.setSettings}
        settingKey={CongSetting.CircuitOverseerMMClosingSong}
        options={MaxSongNumber}
        allowZero
        zeroIsBlank
      />
      <DDMnumbers
        settings={props.settings}
        setSettings={props.setSettings}
        settingKey={CongSetting.SchoolSize}
        options={3}
      />
      <DDMstrings
        settings={props.settings}
        setSettings={props.setSettings}
        settingKey={CongSetting.MMPrintDate}
        options={[
          { label: t("general.week_of"), value: "weekof" },
          { label: t("general.day_of"), value: "dayof" },
        ]}
        labelSuffix={t("conganalysis.attendance.midweek")}
      />
      <CheckBox
        switchType
        settingKey={CongSetting.GenerateAssistantS89}
        settings={props.settings}
        setSettings={props.setSettings}
      />
    </div>
  );
};

export const SettingsWM = (props: {
  settings: CongSettings;
  setSettings: (set: CongSettings) => void;
  myCong: Cong;
  lgroup: number;
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <CheckBox
        switchType
        settingKey={CongSetting.EnableHospitality}
        settings={props.settings}
        setSettings={props.setSettings}
      />
      <CheckBox
        switchType
        settingKey={CongSetting.HospitalityByGroup}
        settings={props.settings}
        setSettings={props.setSettings}
        disabled={!props.settings.enable_hospitality}
      />
      <CheckBox
        switchType
        settingKey={CongSetting.ShowWTReader}
        settings={props.settings}
        setSettings={props.setSettings}
      />
      <CheckBox
        switchType
        settingKey={CongSetting.ShowInterpreter}
        settings={props.settings}
        setSettings={props.setSettings}
      />
      <TextInput
        settingKey={CongSetting.CongregationDisplayName}
        settings={props.settings}
        setSettings={props.setSettings}
        placeholder={languageGroupName(props.lgroup, props.settings)}
      />
      <TextArea
        settingKey={CongSetting.CongregationPTContactInfo}
        settings={props.settings}
        setSettings={props.setSettings}
        labelIcon={HourglassGlobals.isE2E() ? <Unlock /> : undefined}
      />
      <UserDropdown
        settings={props.settings}
        setSettings={props.setSettings}
        settingKey={CongSetting.WTConductor}
        privilege="wt_conductor"
        lgroup={props.lgroup}
      />
      <TextInput settingKey={CongSetting.OtherSpeakerName} settings={props.settings} setSettings={props.setSettings} />
      <TextInput settingKey={CongSetting.OtherSpeakerCong} settings={props.settings} setSettings={props.setSettings} />
      <TextInput
        settingKey={CongSetting.OtherSpeakerTalkTitle}
        settings={props.settings}
        setSettings={props.setSettings}
      />
      <TextInput
        settingKey={CongSetting.CircuitOverseerName}
        settings={props.settings}
        setSettings={props.setSettings}
      />
      <TextInput
        settingKey={CongSetting.CircuitOverseerWMTalkTitle}
        settings={props.settings}
        setSettings={props.setSettings}
      />
      <TextInput
        settingKey={CongSetting.CircuitOverseerWMServiceTalkTitle}
        settings={props.settings}
        setSettings={props.setSettings}
      />
      <DDMnumbers
        settings={props.settings}
        setSettings={props.setSettings}
        settingKey={CongSetting.CircuitOverseerWMClosingSong}
        options={MaxSongNumber}
        allowZero
        zeroIsBlank
      />
      <DDMstrings
        settings={props.settings}
        setSettings={props.setSettings}
        settingKey={CongSetting.WMPrintDate}
        options={[
          { label: t("general.week_of"), value: "weekof" },
          { label: t("general.day_of"), value: "dayof" },
        ]}
        labelSuffix={t("conganalysis.attendance.weekend")}
      />
      <CheckBox
        switchType
        settingKey={CongSetting.WMSpeakerClosingPrayer}
        settings={props.settings}
        setSettings={props.setSettings}
      />
      <CheckBox
        switchType
        settingKey={CongSetting.HideOutgoingSpeakers}
        settings={props.settings}
        setSettings={props.setSettings}
      />
      <TemplateEditor
        settingKey={CongSetting.WMReminderEmailTemplate}
        content={getWMTemplateHTML(props.settings.wm_reminder_email_template)}
        settings={props.settings}
        setSettings={props.setSettings}
        variables={[
          { name: "speaker", label: t("schedules.weekend.speaker.0") },
          { name: "talkTitle", label: t("schedules.general.talk") },
          { name: "date", label: t("general.date") },
          { name: "time", label: t("schedules.general.meeting-time") },
          { name: "congregation", label: t("list.congregation.title") },
          { name: "sender", label: t("general.from") },
        ]}
      />
    </div>
  );
};

export const SettingsCleaning = (props: { settings: CongSettings; setSettings: (set: CongSettings) => void }) => {
  return (
    <div>
      <DDMnumbers
        settingKey={CongSetting.NumberOfCleaningAssignments}
        settings={props.settings}
        setSettings={props.setSettings}
        options={8}
      />
      <TextInput
        settingKey={CongSetting.CleaningLabel1}
        settings={props.settings}
        setSettings={props.setSettings}
        pill={1}
        maxLength={64}
        hidden={props.settings.number_of_cleaning_assignments < 1}
      />
      <TextInput
        settingKey={CongSetting.CleaningLabel2}
        settings={props.settings}
        setSettings={props.setSettings}
        pill={2}
        maxLength={64}
        hidden={props.settings.number_of_cleaning_assignments < 2}
      />
      <TextInput
        settingKey={CongSetting.CleaningLabel3}
        settings={props.settings}
        setSettings={props.setSettings}
        pill={3}
        maxLength={64}
        hidden={props.settings.number_of_cleaning_assignments < 3}
      />
      <TextInput
        settingKey={CongSetting.CleaningLabel4}
        settings={props.settings}
        setSettings={props.setSettings}
        pill={4}
        maxLength={64}
        hidden={props.settings.number_of_cleaning_assignments < 4}
      />
      <TextInput
        settingKey={CongSetting.CleaningLabel5}
        settings={props.settings}
        setSettings={props.setSettings}
        pill={5}
        maxLength={64}
        hidden={props.settings.number_of_cleaning_assignments < 5}
      />
      <TextInput
        settingKey={CongSetting.CleaningLabel6}
        settings={props.settings}
        setSettings={props.setSettings}
        pill={6}
        maxLength={64}
        hidden={props.settings.number_of_cleaning_assignments < 6}
      />
      <TextInput
        settingKey={CongSetting.CleaningLabel7}
        settings={props.settings}
        setSettings={props.setSettings}
        pill={7}
        maxLength={64}
        hidden={props.settings.number_of_cleaning_assignments < 7}
      />
      <TextInput
        settingKey={CongSetting.CleaningLabel8}
        settings={props.settings}
        setSettings={props.setSettings}
        pill={8}
        maxLength={64}
        hidden={props.settings.number_of_cleaning_assignments < 8}
      />
      <CheckBox
        switchType
        settingKey={CongSetting.CleaningNotifyGroup}
        settings={props.settings}
        setSettings={props.setSettings}
      />
    </div>
  );
};

export const SettingsPW = (props: { settings: CongSettings; setSettings: (set: CongSettings) => void }) => {
  const { t } = useTranslation();
  return (
    <div>
      <CheckBox
        switchType
        settingKey={CongSetting.PWEnableOpenScheduling}
        settings={props.settings}
        setSettings={props.setSettings}
      />
      <CheckBox
        switchType
        settingKey={CongSetting.PWEveryoneScheduleAnyone}
        settings={props.settings}
        setSettings={props.setSettings}
      />
      <DDMstrings
        settings={props.settings}
        setSettings={props.setSettings}
        settingKey={CongSetting.PWPairingBehavior}
        options={[
          { label: t("schedules.public-witnessing.gender-family"), value: "sex" },
          { label: t("schedules.fill.all"), value: "any" },
        ]}
        infoMsg={t("schedules.public-witnessing.auto-fill-behavior-info")}
      />
      <CheckBox
        switchType
        settingKey={CongSetting.PWMyAvailability}
        settings={props.settings}
        setSettings={props.setSettings}
      />
    </div>
  );
};

export const SettingsTerr = (props: { settings: CongSettings; setSettings: (set: CongSettings) => void }) => {
  const { t } = useTranslation();
  const tags = useTags();
  const [defaultCheckout, setDefaultCheckout] = useState(terr_checkout_default_tags(props.settings));
  const tileProvider = props.settings.terr_tile_provider;
  // there are some tile providers that we can't use on mobile, mainly because they are vector-only
  const unsupportedCustomMobileTileProvider = (): boolean => {
    return tileProvider === 4;
  };
  if (unsupportedCustomMobileTileProvider() && props.settings.terr_custom_mobile_tiles) {
    props.setSettings({ ...props.settings, terr_custom_mobile_tiles: false });
  }

  const defaultTags = defaultCheckout.flatMap(
    (td) => tagDefaultToTag(td, tags.data ?? [], TagCategory.Territory) ?? [],
  );

  useRunOnce(() => {
    // convert setting to TagDefault[], so it can be saved
    props.setSettings({
      ...props.settings,
      terr_checkout_default_tag: defaultCheckout,
    });
  });

  return (
    <div>
      <DDMnumbers
        settingKey={CongSetting.TerrDisplayCount}
        settings={props.settings}
        setSettings={props.setSettings}
        options={0}
        exactOptions={[5, 10, 15, 20, 25, 30, 40, 50, 500]}
      />
      <CheckBox
        switchType
        settingKey={CongSetting.TerrAllowRequests}
        settings={props.settings}
        setSettings={props.setSettings}
      />
      <TextInput
        settingKey={CongSetting.TerrMaxRequests}
        settings={props.settings}
        setSettings={props.setSettings}
        inputType="number"
        min={0}
        max={999}
        disabled={!props.settings.terr_allow_requests}
        infoMsg={t("settings.territory.disable-requests-after-checkout-info")}
      />
      <CheckBox
        switchType
        settingKey={CongSetting.TerrPubNoReturn}
        settings={props.settings}
        setSettings={props.setSettings}
      />
      <TextInput
        settingKey={CongSetting.TerrCheckoutDurationDays}
        settings={props.settings}
        setSettings={props.setSettings}
        inputType="number"
        min={1}
        max={365}
      />
      <CheckBox
        switchType
        settingKey={CongSetting.TerrAddressHideType}
        settings={props.settings}
        setSettings={props.setSettings}
      />
      <CheckBox
        switchType
        settingKey={CongSetting.TerrCheckoutHideMore}
        settings={props.settings}
        setSettings={props.setSettings}
      />
      <DDMstrings
        settings={props.settings}
        setSettings={props.setSettings}
        settingKey={CongSetting.TerrTileProvider}
        coerceToNumber
        options={[
          { label: "MapTiler Basic", value: "0" },
          { label: "Thunderforest Transport", value: "1" },
          { label: "MapTiler OpenStreetMaps", value: "7" },
          { label: "MapTiler Streets", value: "3" },
          { label: "HERE Explore", value: "4" },
          { label: "MapBox Streets", value: "9" },
          { label: "MapTiler Satellite", value: "5" },
          { label: "MapTiler Hybrid Satellite", value: "8" },
          { label: "MapBox Satellite Streets", value: "10" },
          { label: "Esri Imaging (Satellite)", value: "6" },
          { label: "OpenStreetMaps", value: "2" },
        ]}
      />
      <CheckBox
        switchType
        settingKey={CongSetting.TerrCustomMobileTileProvider}
        settings={props.settings}
        setSettings={props.setSettings}
        infoMsg={t("schedules.settings.territory.mobile-tiles-info")}
      />
      <CheckBox
        switchType
        settingKey={CongSetting.TerrPubUpdateAddresses}
        settings={props.settings}
        setSettings={(settings) => {
          if (!settings.terr_pub_update_address) {
            settings.terr_pub_delete_address = false;
            settings.terr_pub_update_addr_tags = false;
          }
          props.setSettings(settings);
        }}
      />
      <CheckBox
        switchType
        settingKey={CongSetting.TerrPubDeleteAddresses}
        settings={props.settings}
        setSettings={props.setSettings}
        disabled={!props.settings.terr_pub_update_address}
      />
      <CheckBox
        switchType
        settingKey={CongSetting.TerrPubUpdateAddrTags}
        settings={props.settings}
        setSettings={props.setSettings}
        disabled={!props.settings.terr_pub_update_address}
      />
      <CheckBox
        switchType
        settingKey={CongSetting.TerrAddressH2H}
        settings={props.settings}
        setSettings={props.setSettings}
        infoMsg={t("schedules.settings.territory.cong-h2h-info")}
      />
      <CheckBox
        switchType
        settingKey={CongSetting.TerrPubViewAll}
        settings={props.settings}
        setSettings={props.setSettings}
      />
      <CheckBox
        switchType
        settingKey={CongSetting.TerrSortByLocality}
        settings={props.settings}
        setSettings={props.setSettings}
      />
      <CheckBox
        switchType
        settingKey={CongSetting.TerrSharing}
        settings={props.settings}
        setSettings={props.setSettings}
      />
      <Form.Group as={Row} className="mt-1">
        <Form.Label column>{t("schedules.settings.territory.default-tag-checkout")}</Form.Label>
        <Col>
          <TagsDDM
            tags={tags.data?.filter((t) => t.category === TagCategory.Territory) ?? []}
            showAllTag
            showUntaggedTag
            showNonBusinessTag
            selected={defaultTags}
            setSelected={(t: (Tag | PseudoTag)[]) => {
              const selectedDefaults: TagDefault[] = t.flatMap((tag: Tag | PseudoTag) => tagToDefault(tag) ?? []);
              props.setSettings({
                ...props.settings,
                terr_checkout_default_tag: selectedDefaults,
              });
              setDefaultCheckout(selectedDefaults);
            }}
          />
        </Col>
      </Form.Group>
    </div>
  );
};
