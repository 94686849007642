import {
  BorderStyle,
  convertInchesToTwip,
  Footer,
  Header,
  HeadingLevel,
  IBordersOptions,
  IStylesOptions,
  ITableBordersOptions,
  LineRuleType,
  PageNumber,
  PageOrientation,
  Paragraph,
  SimpleField,
  Tab,
  TabStopType,
  TextRun,
} from "docx";
import { Cong } from "../types/cong";

import { PaperSize } from "../types/paper";

export const TABLE_WITH_BORDERS: ITableBordersOptions = {
  top: { size: 10, style: BorderStyle.THICK },
  bottom: { size: 1, style: BorderStyle.THICK },
  left: { size: 1, style: BorderStyle.THICK },
  right: { size: 1, style: BorderStyle.THICK },
  insideHorizontal: { size: 1, style: BorderStyle.THICK },
  insideVertical: { size: 1, style: BorderStyle.THICK },
};

export const TABLE_NO_BORDERS: ITableBordersOptions = {
  top: { size: 0, style: BorderStyle.NONE },
  bottom: { size: 0, style: BorderStyle.NONE },
  left: { size: 0, style: BorderStyle.NONE },
  right: { size: 0, style: BorderStyle.NONE },
  insideHorizontal: { size: 0, style: BorderStyle.NONE },
  insideVertical: { size: 0, style: BorderStyle.NONE },
};

export const NO_BORDERS: IBordersOptions = {
  top: { size: 0, style: BorderStyle.NONE },
  bottom: { size: 0, style: BorderStyle.NONE },
  left: { size: 0, style: BorderStyle.NONE },
  right: { size: 0, style: BorderStyle.NONE },
};

export const PAGE_MARGIN = convertInchesToTwip(0.5);

export type Dimensions = {
  width: number;
  height: number;
};

export const PaperSizesInches: Record<PaperSize, Dimensions> = {
  letter: {
    width: 8.5,
    height: 11,
  },
  a4: {
    width: 8.27,
    height: 11.69,
  },
};

export const StandardStyles: IStylesOptions = {
  default: {
    document: {
      run: { font: "Arial", size: "10pt" },
      paragraph: { spacing: { before: 3 * 20 } },
    },
    title: {
      run: { font: "Arial", size: "12pt", bold: true },
      paragraph: { spacing: { before: 0 } },
    },
    heading1: {
      run: { font: "Arial", size: "10pt", bold: true },
      paragraph: { spacing: { before: 3 * 20, after: 3 * 20 }, indent: { left: 20 } },
    },
    heading2: {
      run: { font: "Arial", size: "10pt", bold: true },
      paragraph: { spacing: { before: 3 * 20, after: 3 * 20 } },
    },
  },
  paragraphStyles: [{ id: "footer", name: "footer", run: { size: 8 * 2 } }],
};

export function pageDimensionsTwip(
  paperSize: PaperSize,
  orientation: (typeof PageOrientation)[keyof typeof PageOrientation] = PageOrientation.PORTRAIT,
): Dimensions {
  const dimInches = PaperSizesInches[paperSize];
  if (orientation === PageOrientation.LANDSCAPE) {
    return {
      width: convertInchesToTwip(dimInches.height),
      height: convertInchesToTwip(dimInches.width),
    };
  }

  return {
    width: convertInchesToTwip(dimInches.width),
    height: convertInchesToTwip(dimInches.height),
  };
}

export function standardFooter(pageWidthTwip: number): Footer {
  return new Footer({
    children: [
      new Paragraph({
        style: "footer",
        children: [
          new TextRun({ children: [PageNumber.CURRENT, " / ", PageNumber.TOTAL_PAGES] }),
          new TextRun({
            children: [new Tab(), new SimpleField("DATE")],
          }),
        ],
        tabStops: [
          {
            type: TabStopType.RIGHT,
            position: pageWidthTwip,
          },
        ],
      }),
    ],
  });
}

export function standardHeader(cong: Cong, title: string, pageWidthTwip: number): Header {
  return new Header({
    children: [
      new Paragraph({
        heading: HeadingLevel.TITLE,
        border: { bottom: { style: BorderStyle.SINGLE, size: 8 } },
        children: [
          new TextRun({ text: cong.name, bold: true }),
          new TextRun({
            children: [new Tab(), title],
          }),
        ],
        tabStops: [
          {
            type: TabStopType.RIGHT,
            position: pageWidthTwip,
          },
        ],
        spacing: {
          after: convertInchesToTwip(0.25),
          lineRule: LineRuleType.EXACT,
        },
      }),
    ],
  });
}
