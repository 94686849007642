import { NameFormat } from "../types/cong";
import HourglassGlobals, { DefaultLocale } from "./globals";
import i18n, { TOptions } from "i18next";
import { selectedCong } from "./langGroups";

export function t(key: string, options?: TOptions): string {
  //for some reason ts seems to get confused about TFunction possibly returning undefined, so we wrap it here
  if (options) return i18n.t(key, options) as unknown as string;
  return i18n.t(key) as unknown as string;
}

export class CollatorSingleton {
  private static collator: Intl.Collator;
  private static locale = "en"; //default locale

  public static setLocale(locale: string) {
    CollatorSingleton.locale = locale;
    CollatorSingleton.collator = new Intl.Collator(CollatorSingleton.locale, {
      sensitivity: "accent",
    });
  }

  public static getInstance(): Intl.Collator {
    if (!CollatorSingleton.collator) {
      CollatorSingleton.setLocale(CollatorSingleton.locale);
    }
    return CollatorSingleton.collator;
  }
}

export function stringToNameFormat(nameFmt: string): NameFormat {
  return nameFmt === "first" ? NameFormat.First : NameFormat.Last;
}

export function localeForUser(congLocale: string, userLocaleId?: number): string {
  // special handling for sign languages - push them to the corresponding written language
  switch (congLocale) {
    case "ise":
      congLocale = "it"; // italian sign language displays in italian
      break;
    case "asf":
      congLocale = "en"; // australian sign language displays in english
      break;
    case "psr": // portuguese sign language
      congLocale = "pt-PT";
      break;
    case "bzs":
      congLocale = "pt-BR";
      break;
  }
  if (!userLocaleId) return congLocale;

  const userLocale = HourglassGlobals.locales().find((l: Locale) => l.id === userLocaleId);
  return userLocale?.code || congLocale;
}

export function getLocaleWithCode(code: string): Locale {
  const locs = HourglassGlobals.locales().filter((lc) => lc.code === code);
  if (locs.length > 0) {
    return locs[0] ?? DefaultLocale;
  }

  console.warn("getLocaleWithCode cannot find locale for code", code, "falling back");
  return HourglassGlobals.locales()[0] ?? DefaultLocale;
}

export function getLocaleWithSymbol(symbol: string): Locale | undefined {
  return HourglassGlobals.locales().find((lc) => lc.symbol === symbol);
}

// In some languages, the rotation of vertical text 180 degrees doesn't work properly. This helper returns the name
// of the class to use based on the locale.
export function verticalTextTransformClass(localeCode: string): string {
  const className = "transform180";
  switch (localeCode) {
    case "ko":
      return "";
    default:
      return className;
  }
}

function normalizeLower(str: string): string {
  return str
    .trim()
    .toLocaleLowerCase()
    .normalize("NFD")
    .replace(/\p{Diacritic}/gu, "");
}

// containsFuzzy performs case-insensitive matching to see if haystack contains needle. it ignores diacritics/accents.
export function containsFuzzy(haystack: string, needle: string): boolean {
  return normalizeLower(haystack).includes(normalizeLower(needle));
}

// NoPDFLocales are locale codes for which we cannot generate PDFs reliably: jsPDF does not support the needed
// features for the fonts. https://github.com/parallax/jsPDF/issues/2778
const NoPDFLocales = new Set<string>(["hi", "ne"]);

// isNoPDFLocale checks the uiCode and congId and returns true if the locale for either is in the NoPDFLocales set
export function isNoPDFLocale(uiCode: string, congId: number): boolean {
  if (NoPDFLocales.has(uiCode)) return true;
  // find the congId in my cong and all lang groups
  const cong = selectedCong(congId);
  if (!cong) return false;
  return NoPDFLocales.has(cong.locale.code);
}
